import React, {useEffect} from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
const Authenticatore = () => {
    const navigate = useNavigate()
    const user = useSelector(state => state.user)
    useEffect(() => {
        if (user.isSignedIn === false) {
            navigate('/login')
        }
        else if (user.isSignedIn) {
            navigate('/dashboard')
        }
    }
        , [user, navigate])
}

export default Authenticatore