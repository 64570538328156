import React, { useState, useRef, useEffect } from "react";
import Logo from "../../assets/logo.png";
import UserOut from "../../assets/userOut.png";
import UserIn from "../../assets/userIn.png";
import { useSelector } from "react-redux";
import UserModal from "./components/UserModal";

const Header = ({ fromLogin = false }) => {
  const User = useSelector((state) => state.user);
  const [showUserModal, setShowUserModal] = useState(false);
  const { isSignedIn } = User;

  const modalWrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalWrapperRef.current &&
        !modalWrapperRef.current.contains(event.target)
      ) {
        setShowUserModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="w-full">
      <div className="HEADER flex flex-row items-center justify-between py-4 border-b border-[#DDDDDD] border-solid w-full gap-4">
        <div className="flex flex-row items-center justify-between gap-4">
          <img src={Logo} alt="Logo" width={75} height={75} />
          <h1 className="font-sans font-semibold text-[24px]">
            {" "}
            The Arqam Schools{" "}
          </h1>
        </div>
        {!fromLogin ? (
          !isSignedIn ? (
            <img
              src={UserOut}
              alt="User Out"
              width={30}
              height={30}
              className="hover:cursor-pointer"
            />
          ) : (
            <img
              src={UserIn}
              alt="User In"
              width={30}
              height={30}
              className="hover:cursor-pointer"
              onClick={() => {
                setShowUserModal(true);
              }}
            />
          )
        ) : (
          ""
        )}
      </div>
      {showUserModal && (
        <>
          <div
            className="fixed top-0 left-0 w-full h-full z-9999 bg-opacity-50 bg-black"
            onClick={() => setShowUserModal(false)}
          />
          <div className="relative" ref={modalWrapperRef}>
            <UserModal closeModal={() => setShowUserModal(false)} />
          </div>
        </>
      )}
    </div>
  );
};

export default Header;
