import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getStudentByRollNo,
  resetAdmissionState,
  removeStudent,
  startLoading,
  markArrearsPaid,
} from "../../actions/admissionActions";
import Header from "../header/Header";
import Loader from "../../components/Loader";
import {
  getIndividualPaidVouchers,
} from "../../actions/voucherActions";
const StudentRemoval = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const User = useSelector((state) => state.user);
  //////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (!User.isSignedIn) {
      navigate("/login");
    }
  }, [User, navigate]);
  //////////////////////////////////////////////////////////////////////////
  const Admission = useSelector((state) => state.admission);
  const Vouchers = useSelector((state) => state.voucher.vouchers);
  const loading = Admission.loading;
  const [rollNo, setRollNo] = useState("");
  const [name, setName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [studentClass, setStudentClass] = useState("");
  const [section, setSection] = useState("");
  const [arrears, setArrears] = useState("");
  const [leftDate, setLeftDate] = useState("");
  const [allowSubmit, setAllowSubmit] = useState(false);


  useEffect(() => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const initialDate = `${year}-${month}-${day}`;
    setLeftDate(initialDate);
  }, []);

  useEffect(() => {
    let timeoutId;
    const fetchStudentData = () => {
      if (rollNo !== "") {
        dispatch(startLoading());
        dispatch(getStudentByRollNo(rollNo, User.campus));
        dispatch(getIndividualPaidVouchers(User.campus, rollNo));
      }
    };
    // Set a timeout to delay the function call
    timeoutId = setTimeout(fetchStudentData, 500); // Adjust the delay time as needed
    // Clear the previous timeout if the rollNo changes before it fires
    return () => clearTimeout(timeoutId);
  }, [rollNo, dispatch]);

  useEffect(() => {
    // Check if Admission is truthy before updating the state
    if (Admission && Admission.studentPersonalInfo) {
      setName(Admission.studentPersonalInfo.studentName);
      setFatherName(Admission.studentPersonalInfo.fatherName);
      setStudentClass(Admission.studentPersonalInfo.studentClass);
      setSection(Admission.studentPersonalInfo.section);
      // setArrears(Admission.studentMonthlyFeeInfo.arrears);
      // setAllowSubmit(true);
    } else {
      setName("");
      setFatherName("");
      setStudentClass("");
      setSection("");
      setArrears("");
      setAllowSubmit(false);
    }
  }, [Admission]);

  useEffect(() => {
    if (Admission && Admission.failed) {
      alert(Admission.msg);
      setAllowSubmit(false);
      dispatch(resetAdmissionState());
    } else if (Admission.msg === "Student removed successfully") {
      alert(Admission.msg);
      navigate(-1);
      dispatch(resetAdmissionState());
    } else if (Admission.msg === "Arrears marked paid successfully") {
      alert(Admission.msg);
      dispatch(resetAdmissionState());
      setArrears(0);
    }
  }, [Admission]);

  useEffect(() => {
    if (Vouchers && Vouchers.length > 0) {
      if (Vouchers[0].feePaid === false) {
        setArrears(Vouchers[0].monthlyFee + Vouchers[0].arrears + Vouchers[0].examinationFee - Vouchers[0].discount);
      }
      else {
        setArrears(0);
        setAllowSubmit(true);
      }
    }
  }, [Vouchers]);
  const handleRollNumberInput = (e) => {
    const newRollNo = e.target.value;
    setRollNo(newRollNo);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(startLoading());
    dispatch(removeStudent(rollNo, leftDate));

  };

  const handleMarkArrearsPaid = () => {
    const body = {
      arrears: arrears,
      receivedBy: User.user,
      receivedDate: new Date(),
    };
    dispatch(startLoading());
    dispatch(markArrearsPaid(rollNo, body));
  };

  return (
    <div className="flex flex-col items-start justify-center px-4">
      {loading && <Loader />}

      <Header />
      <div className="flex flex-row items-center justify-start w-full border-b border-solid border-[#DDDDDD] py-2">
        <h1 className="text-3xl font-bold text-center text-gray-800">
          Student Removal
        </h1>
        <h2 className="ml-4 text-xl font-semibold text-center text-gray-500">
          {User.campus} Campus
        </h2>
      </div>
      <div className="flex flex-row items-start justify-between w-full mt-4 gap-4 ">
        <div className="flex flex-col items-start justify-center w-full ">
          <form className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2 border-b border-solid border-[#DDDDDD] py-2">
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Roll No.
              </label>
              <input
                type="number"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Roll No."
                value={rollNo}
                onChange={handleRollNumberInput}
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">Name</label>
              <input
                type="text"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Name"
                value={name}
                disabled
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Father Name
              </label>
              <input
                type="text"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Father Name"
                value={fatherName}
                disabled
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">Class</label>
              <input
                type="text"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Class"
                value={studentClass}
                disabled
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Section
              </label>
              <input
                type="text"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Section"
                value={section}
                disabled
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Arrears
              </label>
              {arrears > 0 && (
                <label className="text-sm font-medium text-red-800">
                  Fee payment is pending
                </label>
              )}
              <input
                type="number"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md text-red-500"
                placeholder="Arrears"
                value={arrears}
                disabled
              />
            </div>

            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Left Date
              </label>
              <input
                type="date"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Left Date"
                value={leftDate}
                onChange={(e) => setLeftDate(e.target.value)}
              />
            </div>
          </form>
          <div className="flex flex-row items-center justify-center w-full gap-4 mt-4">
            <button
              className={
                allowSubmit
                  ? "px-4 py-2 text-base font-bold text-white bg-green-900 rounded-md"
                  : "px-4 py-2 text-base font-bold text-white bg-gray-500 rounded-md hover:cursor-not-allowed"
              }
              disabled={!allowSubmit}
              onClick={handleSubmit}
            >
              Remove
            </button>
            <button
              className="px-4 py-2 text-base font-bold text-white bg-red-500 rounded-md"
              onClick={() => {
                navigate(-1);
                dispatch(resetAdmissionState());
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentRemoval;
