import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
//////////////////////////////////////////////////////////////////////////
import Header from "../header/Header";
import {
  postAdmission,
  startLoading,
  getLastRollNo,
  resetAdmissionState,
} from "../../actions/admissionActions";
import { classes, sections } from "../../consts/commons";
import Loader from "../../components/Loader";
const NewAdmissions = () => {
  // Fixed items and states
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //////////////////////////////////////////////////////////////////////////
  const user = useSelector((state) => state.user);
  //////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (!user.isSignedIn) {
      navigate("/login");
    }
  }, [user, navigate]);
  //////////////////////////////////////////////////////////////////////////

  const [showStudentInfo, setShowStudentInfo] = useState(true);
  const admissionLoading = useSelector((state) => state.admission.loading);
  const lastRollNo = useSelector((state) => state.admission.lastRollNo);
  const admissionMessage = useSelector((state) => state.admission.msg);
  const campus = useSelector((state) => state.user.campus);
  //////////////////////////////////////////////////////////////////////////

  // Student Information
  const [admissionNo, setAdmissionNo] = useState(null);
  const [admissionDate, setAdmissionDate] = useState("");
  const [registrationNo, setRegistrationNo] = useState(null);
  //////////////////////////////////////////////////////////////////////////

  const [studentName, setStudentName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [placeOfBirth, setPlaceOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [religion, setReligion] = useState("");
  //////////////////////////////////////////////////////////////////////////

  const [address, setAddress] = useState("");
  const [fatherCnic, setFatherCnic] = useState("");
  const [fatherOccupation, setFatherOccupation] = useState("");
  const [fatherHomePhone, setFatherHomePhone] = useState("");
  const [fatherOfficePhone, setFatherOfficePhone] = useState("");
  const [telephone, setTelephone] = useState("");
  const [fatherEmail, setFatherEmail] = useState("");
  //////////////////////////////////////////////////////////////////////////

  const [studentClass, setStudentClass] = useState("");
  const [section, setSection] = useState("");
  const [rollNo, setRollNo] = useState("");
  //////////////////////////////////////////////////////////////////////////

  const studentInformation = () => {
    return (
      <div className="flex flex-row items-start justify-between w-full mt-4 gap-4 ">
        <div className="flex flex-col items-start justify-center w-full ">
          <form className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2 border-b border-solid border-[#DDDDDD] py-2">
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Admission No.
              </label>
              <input
                type="number"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Admission No."
                value={admissionNo}
                onChange={(e) => setAdmissionNo(e.target.value)}
                disabled
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Registration No.
              </label>
              <input
                type="number"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Registration No."
                value={registrationNo}
                onChange={(e) => setRegistrationNo(e.target.value)}
                disabled
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Admission Date
              </label>
              <input
                type="date"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Admission Date"
                value={admissionDate}
                onChange={(e) => setAdmissionDate(e.target.value)}
              />
            </div>
          </form>
          <form className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2 border-b border-solid border-[#DDDDDD] py-2">
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Student Name
              </label>
              <input
                type="text"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Student Name"
                value={studentName}
                onChange={(e) => setStudentName(e.target.value)}
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Father Name
              </label>
              <input
                type="text"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Father Name"
                value={fatherName}
                onChange={(e) => setFatherName(e.target.value)}
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Date of Birth
              </label>
              <input
                type="date"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Date of Birth"
                value={dateOfBirth}
                onChange={(e) => setDateOfBirth(e.target.value)}
              />
            </div>

            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Place of Birth
              </label>
              <input
                type="text"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Place of Birth"
                value={placeOfBirth}
                onChange={(e) => setPlaceOfBirth(e.target.value)}
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Gender
              </label>
              <select
                className="px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <option value="" disabled>
                  Select Gender
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Religion
              </label>
              <input
                type="text"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Religion"
                value={religion}
                onChange={(e) => setReligion(e.target.value)}
              />
            </div>
          </form>
          <form className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2 border-b border-solid border-[#DDDDDD] py-2">
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Address
              </label>
              <textarea
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Father CNIC
              </label>
              <input
                type="number"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Father CNIC"
                value={fatherCnic}
                onChange={(e) => setFatherCnic(e.target.value)}
              />
            </div>

            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Father Occupation
              </label>
              <input
                type="text"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Father Occupation"
                value={fatherOccupation}
                onChange={(e) => setFatherOccupation(e.target.value)}
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Mother Phone Number
              </label>
              <input
                type="number"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Mother Phone number"
                value={telephone}
                onChange={(e) => setTelephone(e.target.value)}
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Father Phone Number
              </label>
              <input
                type="number"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Father Phone number"
                value={fatherHomePhone}
                onChange={(e) => setFatherHomePhone(e.target.value)}
              />
            </div>

            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Office/Shop  Number
              </label>
              <input
                type="number"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder=" Office/Shop  Number"
                value={fatherOfficePhone}
                onChange={(e) => setFatherOfficePhone(e.target.value)}
              />
            </div>


            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Father Email
              </label>
              <input
                type="email"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Father Email"
                value={fatherEmail}
                onChange={(e) => setFatherEmail(e.target.value)}
              />
            </div>
          </form>
        </div>
        <div className="flex flex-col items-start justify-center w-full gap-2">
          <form className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2 border-b border-solid border-[#DDDDDD] py-2">
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">Class</label>
              <select
                className="px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                value={studentClass}
                onChange={(e) => setStudentClass(e.target.value)}
              >
                <option value="" disabled>
                  Select Class
                </option>
                {classes.map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </div>

            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Section
              </label>
              <select
                className="px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                value={section}
                onChange={(e) => setSection(e.target.value)}
              >
                <option value="" disabled>
                  Select Section
                </option>
                {sections.map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </div>

            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Roll No.
              </label>
              <input
                type="string"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Roll No."
                value={rollNo}
                onChange={(e) => setRollNo(e.target.value)}
                disabled
              />
            </div>
          </form>
        </div>
      </div>
    );
  };
  // End of Student Information

  // Financial Information
  const [registrationFee, setRegistrationFee] = useState(null);
  const [admissionFee, setAdmissionFee] = useState(null);
  const [securityFee, setSecurityFee] = useState(null);
  const [monthlyTuitionFee, setMonthlyTuitionFee] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [netMonthlyFee, setNetMonthlyFee] = useState(null);
  const [transportFee, setTransportFee] = useState(null);
  const [arrears, setArrears] = useState(null);
  const [examinationFee, setExaminationFee] = useState(null);
  const [totalReceivable, setTotalReceivable] = useState(null);
  //////////////////////////////////////////////////////////////////////////

  const [voucherType, setVoucherType] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [cashReceiptDate, setCashReceiptDate] = useState("");
  const [receivedAmount, setReceivedAmount] = useState(null);
  //////////////////////////////////////////////////////////////////////////

  const calculateNetMonthlyFee = () => {
    const netMonthlyFee = monthlyTuitionFee - discount;
    setNetMonthlyFee(netMonthlyFee);
  };
  //////////////////////////////////////////////////////////////////////////

  const calculateTotalReceivable = () => {
    const totalReceivable =
      monthlyTuitionFee
      +registrationFee
      +admissionFee
      +examinationFee
      
      -
      discount
    setTotalReceivable(totalReceivable);
  };
  //////////////////////////////////////////////////////////////////////////

  const financialInformation = () => {
    return (
      <div className="flex flex-row items-start justify-between w-full mt-4 gap-4 ">
        <div className="flex flex-col items-start justify-center w-full ">
          <form className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2 border-b border-solid border-[#DDDDDD] py-2">
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Registration Fee
              </label>
              <input
                type="number"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Registration Fee"
                value={parseFloat(registrationFee)}
                onChange={(e) => setRegistrationFee(parseFloat(e.target.value))}
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Admission Fee
              </label>
              <input
                type="number"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Admission Fee"
                value={admissionFee}
                onChange={(e) => setAdmissionFee(parseFloat(e.target.value))}
              />
            </div>
            {/* <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Security Fee
              </label>
              <input
                type="number"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Security Fee"
                value={securityFee}
                onChange={(e) => setSecurityFee(parseFloat(e.target.value))}
              />
            </div> */}
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-red-500">
                Monthly Tuition Fee
              </label>
              <input
                type="number"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md text-red-500"
                placeholder="Monthly Tuition Fee"
                value={monthlyTuitionFee}
                onChange={(e) =>
                  setMonthlyTuitionFee(parseFloat(e.target.value))
                }
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Discount
              </label>
              <input
                type="number"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Discount"
                value={discount}
                onChange={(e) => setDiscount(parseFloat(e.target.value))}
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-green-600">
                Net Monthly Fee
              </label>
              <input
                type="number"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md  text-red-500"
                placeholder="Net Monthly Fee"
                value={netMonthlyFee}
                disabled
              />
            </div>
            {/* <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Transport Fee
              </label>
              <input
                type="number"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md text-gray-800"
                placeholder="Transport Fee"
                value={transportFee}
                onChange={(e) => setTransportFee(parseFloat(e.target.value))}
              />
            </div> */}
            {/* <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Arrears
              </label>
              <input
                type="number"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md text-gray-800"
                placeholder="Arrears"
                value={arrears}
                onChange={(e) => setArrears(parseFloat(e.target.value))}
              />
            </div> */}
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Examination Fee
              </label>
              <input
                type="number"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md text-gray-800"
                placeholder="Examination Fee"
                value={examinationFee}
                onChange={(e) => setExaminationFee(parseFloat(e.target.value))}
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-xl font-bold text-gray-800">
                Total Receivable
              </label>
              <input
                type="number"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md text-xl font-bold text-gray-800"
                placeholder="Total Receivable"
                value={totalReceivable}
                disabled
              />
            </div>
          </form>
        </div>
        {/* voucher type, dates div */}
        {/* <div className="flex flex-col items-start justify-center w-full gap-2">
          <form className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2 border-b border-solid border-[#DDDDDD] py-2">
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Voucher Type
              </label>
              <select
                className="px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                value={voucherType}
                onChange={(e) => setVoucherType(e.target.value)}
              >
                <option value="" disabled>
                  Select Voucher Type
                </option>
                <option value="cash">Cash</option>
                <option value="bank">Bank</option>
                <option value="cheque">Cheque</option>
              </select>
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Issue Date
              </label>
              <input
                type="date"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Issue Date"
                value={issueDate}
                onChange={(e) => setIssueDate(e.target.value)}
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Due Date
              </label>
              <input
                type="date"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Due Date"
                value={dueDate}
                onChange={(e) => setDueDate(e.target.value)}
              />
            </div>

            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                From Date
              </label>
              <input
                type="date"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="From Date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                To Date
              </label>
              <input
                type="date"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="To Date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Cash Receipt Date
              </label>
              <input
                type="date"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Cash Receipt Date"
                value={cashReceiptDate}
                onChange={(e) => setCashReceiptDate(e.target.value)}
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Received Amount
              </label>
              <input
                type="number"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Received Amount"
                value={receivedAmount}
                onChange={(e) => setReceivedAmount(e.target.value)}
              />
            </div>
          </form>
        </div> */}
      </div>
    );
  };

  // Use Effect hook that will be called when program starts
  useEffect(() => {
    dispatch(startLoading());
    dispatch(getLastRollNo());
    // Move this to sign in
    const date = new Date();
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const initialDate = `${year}-${month}-${day}`;

    setAdmissionDate(initialDate);
    setIssueDate(initialDate);
    setCashReceiptDate(initialDate);

    // Set due date 10 days after issue date
    const issueDateObject = new Date(initialDate);
    issueDateObject.setDate(issueDateObject.getDate() + 10);

    const dueDay = String(issueDateObject.getDate()).padStart(2, "0");
    const dueMonth = String(issueDateObject.getMonth() + 1).padStart(2, "0");
    const dueYear = issueDateObject.getFullYear();
    setDueDate(`${dueYear}-${dueMonth}-${dueDay}`);

    // Set from date to 1st of current month
    const fromDateObject = new Date(initialDate);
    fromDateObject.setDate(1);

    const fromDay = String(fromDateObject.getDate()).padStart(2, "0");
    const fromMonth = String(fromDateObject.getMonth() + 1).padStart(2, "0");
    const fromYear = fromDateObject.getFullYear();
    setFromDate(`${fromYear}-${fromMonth}-${fromDay}`);

    // Set date to the last day of the current month
    const toDateObject = new Date();
    toDateObject.setMonth(toDateObject.getMonth() + 1, 0); // Set to the last day of the current month

    const toDay = String(toDateObject.getDate()).padStart(2, "0");
    const toMonth = String(toDateObject.getMonth() + 1).padStart(2, "0");
    const toYear = toDateObject.getFullYear();

    setToDate(`${toYear}-${toMonth}-${toDay}`);
    setAdmissionNo(parseInt(lastRollNo) + 1);
    setRollNo(parseInt(lastRollNo) + 1);
    setRegistrationNo(parseInt(lastRollNo) + 1);
  }, []);

  // use effecto to set admission no, roll no and registration no
  useEffect(() => {
    setAdmissionNo(parseInt(lastRollNo) + 1);
    setRollNo(parseInt(lastRollNo) + 1);
    setRegistrationNo(parseInt(lastRollNo) + 1);
  }, [lastRollNo]);

  // Hook to calculate net monthly fee
  useEffect(() => {
    calculateNetMonthlyFee();
  }, [monthlyTuitionFee, discount]);

  // Hook to calculate total receivable
  useEffect(() => {
    calculateTotalReceivable();
  }, [
    registrationFee,
    admissionFee,
    securityFee,
    monthlyTuitionFee,
    discount,
    // transportFee,
  ]);
  //////////////////////////////////////////////////////////////////////////

  // Make a useEffect to check if due date or to date is changed and not less than issue date or from date respectively
  useEffect(() => {
    const issueDateObject = new Date(issueDate);
    const dueDateObject = new Date(dueDate);

    if (dueDateObject < issueDateObject) {
      setDueDate(issueDate);
    }
  }, [issueDate, dueDate]);
  //////////////////////////////////////////////////////////////////////////

  // Use effect to check if to date is less than from date
  useEffect(() => {
    const fromDateObject = new Date(fromDate);
    const toDateObject = new Date(toDate);

    if (toDateObject < fromDateObject) {
      setToDate(fromDate);
    }
  }, [fromDate, toDate]);
  //////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (admissionMessage === "Admission added successfully") {
      alert(admissionMessage);
      dispatch(resetAdmissionState());
      navigate(-1);
    } else if (admissionMessage === "Admission failed, please try again") {
      alert(admissionMessage);
      dispatch(resetAdmissionState());
    }
  }, [admissionMessage]);

  const proceedToFinancialInformation = () => {
    setShowStudentInfo(false);
  };
  //////////////////////////////////////////////////////////////////////////

  const proceedToStudentInformation = () => {
    setShowStudentInfo(true);
  };
  //////////////////////////////////////////////////////////////////////////

  const submitForm = async () => {
    // Check if all fields are filled
    if (studentName === "" || fatherName === "" || dateOfBirth === "" || placeOfBirth === "" || gender === "" || religion === "" || address === "" || fatherCnic === "" || fatherOccupation === "" || fatherHomePhone === "" || fatherOfficePhone === "" || telephone === "" || fatherEmail === "" || studentClass === "" || section === "" || rollNo === "" || monthlyTuitionFee === "" || discount === "" || netMonthlyFee === "" || registrationFee === "" || admissionFee === "" || examinationFee === "") { alert("Please fill all the fields"); return }


    const voucherNo = studentClass + section + rollNo + "adm";
    const feePeriod = fromDate + " to " + toDate;

    const data = {
      studentData: {
        admissionNo,
        admissionDate,
        registrationNo,
        studentName,
        fatherName,
        dateOfBirth,
        placeOfBirth,
        gender,
        religion,
        address,
        fatherCnic,
        fatherOccupation,
        fatherHomePhone,
        fatherOfficePhone,
        telephone,
        fatherEmail,
        studentClass,
        section,
        rollNo,
        campus,
      },
      financialData: {
        studentName,
        rollNo,
        studentClass,
        section,
        campus,
        voucherNo,
        feePeriod,
        issueDate,
        dueDate,

        monthlyFee: monthlyTuitionFee,
        discount,
        netMonthlyFee,
        registrationFee,
        admissionFee,
        examinationFee,
        receivedAmount,
        receivedBy: user.user,
        receivedDate: cashReceiptDate,
        feePaid: true,
        posted: true,
        note: "",
        monthGenerated: "",
        yearGenerated: "",
      },
    };
    console.log(rollNo, "HERE")
    dispatch(startLoading());
    dispatch(postAdmission(data));
  };
  //////////////////////////////////////////////////////////////////////////

  return (
    <div className="flex flex-col items-start justify-center px-4">
      {admissionLoading && <Loader />}
      <Header />
      <div className="flex flex-col items-start justify-center w-full border-b border-solid border-[#DDDDDD] py-2 px-1">
        <div className="flex flex-row items-center justify-start w-full gap-4">
          <h1 className="text-3xl font-bold text-center text-gray-800">
            New Admissions
          </h1>
          <h2 className="text-xl font-semibold text-center text-gray-500">
            {showStudentInfo ? "Student Information" : "Financial Information"}
          </h2>
        </div>
        <h2 className="text-xl font-semibold text-center text-gray-500">
          {campus} Campus
        </h2>
      </div>
      {showStudentInfo ? studentInformation() : financialInformation()}
      <div className="flex flex-row items-center justify-center w-full gap-4 mt-4">
        {showStudentInfo && (
          <button
            className="px-4 py-2 text-base font-bold text-white bg-green-500 rounded-md"
            onClick={proceedToFinancialInformation}
          >
            Next
          </button>
        )}
        {!showStudentInfo && (
          <button
            className="px-4 py-2 text-base font-bold text-white bg-green-500 rounded-md"
            onClick={submitForm}
          >
            Submit
          </button>
        )}
        {!showStudentInfo && (
          <button
            className="px-4 py-2 text-base font-bold text-white bg-blue-500 rounded-md"
            onClick={proceedToStudentInformation}
          >
            Back
          </button>
        )}

        <button
          className="px-4 py-2 text-base font-bold text-white bg-red-500 rounded-md"
          onClick={() => navigate(-1)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default NewAdmissions;
