import { address } from "./server";
import axios from "axios";

export const generateVoucherCampus = (campus, data) => {
  const url = `${address}/finances/generateVoucher/campus/${campus}`;
  return async (dispatch) => {
    await axios
      .post(url, data)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "GENERATE_VOUCHER_CAMPUS",
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "GENERATE_VOUCHER_FAILED",
          payload: err,
        });
      });
  };
};

export const generateVoucherStudent = (rollNo, data) => {
  const url = `${address}/finances/generateVoucher/student/${rollNo}`;
  return async (dispatch) => {
    await axios
      .post(url, data)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "GENERATE_VOUCHER_STUDENT",
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "GENERATE_VOUCHER_FAILED",
          payload: err,
        });
      });
  };
};

export const addAdditionalCharges = (data) => {
  const url = `${address}/finances/addAdditionalCharges`;
  return async (dispatch) => {
    await axios
      .post(url, data)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "ADD_ADDITIONAL_CHARGES",
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "ADD_ADDITIONAL_CHARGES_FAILED",
          payload: err,
        });
      });
  };
};
export const checkUnpostedVouchers = (campus) => {
  const url = `${address}/finances/checkIfVoucherNeedsToBePosted/${campus}`;
  return async (dispatch) => {
    await axios
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "CHECK_UNPOSTED_VOUCHERS",
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "CHECK_UNPOSTED_VOUCHERS_FAILED",
          payload: err,
        });
      });
  };
};
export const resetVoucher = () => {
  return async (dispatch) => {
    dispatch({
      type: "RESET_VOUCHER",
    });
  };
};

export const postVouchers = (campus, month) => {
  const url = `${address}/finances/postVouchers/${campus}/${month}`;
  return async (dispatch) => {
    await axios
      .post(url)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "POST_VOUCHERS",
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "POST_VOUCHERS_FAILED",
          payload: err,
        });
      });
  };
};

export const unpostVouchers = (campus, month) => {
  const url = `${address}/finances/unpostVouchers/${campus}/${month}`;
  return async (dispatch) => {
    await axios
      .post(url)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "UNPOST_VOUCHERS",
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "UNPOST_VOUCHERS_FAILED",
          payload: err,
        });
      });
  };
};

export const getIndividualVoucher = (campus, rollNo) => {
  const url = `${address}/finances/individualVoucher/${campus}/${rollNo}`;
  return async (dispatch) => {
    await axios
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "GET_INDIVIDUAL_VOUCHER",
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "GET_INDIVIDUAL_VOUCHER_FAILED",
          payload: err,
        });
      });
  };
};

export const payIndividualVoucher = (campus, rollNo, data) => {
  const url = `${address}/finances/markIndividualVoucherAsPaid/${campus}/${rollNo}`;
  return async (dispatch) => {
    await axios
      .post(url, data)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "PAY_INDIVIDUAL_VOUCHER",
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "PAY_INDIVIDUAL_VOUCHER_FAILED",
          payload: err,
        });
      });
  };
};

export const getIndividualPaidVouchers = (campus, rollNo, type = '') => {
  const url = `${address}/finances/individualAllVouchers/${campus}/${rollNo}/${type}`;
  return async (dispatch) => {
    await axios
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "GET_INDIVIDUAL_ALL_VOUCHER",
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "GET_INDIVIDUAL_ALL_VOUCHER_FAILED",
          payload: err,
        });
      });
  };
};

export const startLoading = () => {
  return async (dispatch) => {
    dispatch({
      type: "START_VOUCHER_LOADING",
    });
  };
};

export const markArrearsPaid = (rollNo) => {
  const url = `${address}/finances/markArrearsPaid/${rollNo}}`;
  return async (dispatch) => {
    await axios
      .post(url)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "MARK_ARREARS_PAID",
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "MARK_ARREARS_PAID_FAILED",
          payload: err,
        });
      });
  };
};

export const getAllVouchers = (data, campus, type, status) => {
  const url = `${address}/finances/getAllVouchers/${type}/${campus}/${status}`
  return async (dispatch) => {
    await axios
      .post(url, data)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "GET_ALL_VOUCHERS",
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "GET_ALL_VOUCHERS_FAILED",
          payload: err,
        });
      });
  };
}

export const getUnPostedVouchers = (campus, month) => {
  const url = `${address}/finances/getUnpostedVouchers/${campus}/${month}`
  return async (dispatch) => {
    await axios
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "GET_UNPOSTED_VOUCHERS",
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "GET_UNPOSTED_VOUCHERS_FAILED",
          payload: err,
        });
      });
  };
}

export const getPostedVouchers = (campus, month) => {
  const url = `${address}/finances/getPostedVouchers/${campus}/${month}`
  return async (dispatch) => {
    await axios
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "GET_POSTED_VOUCHERS",
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "GET_POSTED_VOUCHERS_FAILED",
          payload: err,
        });
      });
  };
}