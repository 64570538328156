import { address } from "./server";
import axios from "axios";
export const signIn = (userId, password) => {
  const url = `${address}/users/signin`;
  return (dispatch) => {
    axios
      .post(url, {
        userId,
        password,
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "SIGN_IN",
            payload: res.data,
          });
        } else if (res.status === 401) {
          dispatch({
            type: "INVALID_CREDENTIALS",
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "SIGN_IN_FAILED",
          payload: err,
        });
      });
  };
};

export const signOut = () => {
  return (dispatch) => {
    dispatch({
      type: "SIGN_OUT",
    });
  };
};

export const startLoading = () => {
  return (dispatch) => {
    dispatch({
      type: "START_LOADING",
    });
  };
}