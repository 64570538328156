const initialState = {
  isSignedIn: false,
  user: null,
  loading: false,
  role: null,
  failed: false,
  msg: null,
  campus: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SIGN_IN":
      return {
        ...state,
        isSignedIn: true,
        user: action.payload.data.userId,
        role: action.payload.data.role,
        campus: action.payload.data.campus,
        loading: false,
        failed: false,
        msg: null,
      };
    case "INVALID_CREDENTIALS":
      return {
        ...state,
        failed: true,
        loading: false,
        msg: action.payload.msg,
      };
    case "SIGN_IN_FAILED":
      return {
        ...state,
        failed: true,
        loading: false,
        msg: action.payload.msg,
      };
    case "SIGN_OUT":
      return {
        ...state,
        isSignedIn: false,
        user: null,
        role: null,
        campus: null,
        loading: false,
        failed: false,
        msg: null,
      };
    case "START_LOADING":
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default userReducer;
