import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../header/Header";
import {
  startLoading as startAdmissionLoading,
  getStudentByRollNo,
  resetAdmissionState,
} from "../../actions/admissionActions";
import { addAdditionalCharges, startLoading, resetVoucher, getIndividualVoucher } from "../../actions/voucherActions";
import Loader from "../../components/Loader";
import { classes, sections } from "../../consts/commons";

const AdditionalCharges = () => {
  const User = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  //////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (!User.isSignedIn) {
      navigate("/login");
    }
  }, [User, navigate]);
  //////////////////////////////////////////////////////////////////////////
  const Admission = useSelector((state) => state.admission);
  const admissionLoading = Admission.loading;
  const loading = useSelector((state) => state.voucher.loading);
  const msg = Admission.msg;
  const voucherMsg = useSelector((state) => state.voucher.msg);
  const voucherData = useSelector((state) => state.voucher.vouchers)
 
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [rollNo, setRollNo] = useState("");
  const [examinationFee, setExaminationFee] = useState("");
  const [note, setNote] = useState("");
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [studentNotFound, setStudentNotFound] = useState(false);
  useEffect(() => {
    let timeoutId;
    const fetchStudentData = () => {
      if (rollNo !== "") {
        dispatch(startAdmissionLoading());
        dispatch(getStudentByRollNo(rollNo, User.campus));
        dispatch(getIndividualVoucher(User.campus, rollNo))
      } else {
        setRollNo("");
      }
    };
    // Set a timeout to delay the function call
    timeoutId = setTimeout(fetchStudentData, 500); // Adjust the delay time as needed
    // Clear the previous timeout if the rollNo changes before it fires
    return () => clearTimeout(timeoutId);
  }, [rollNo, dispatch]);
  useEffect(() => {
    if (msg === "Student not found" || msg === "Student does not exist") {
      setStudentNotFound(true);
      setLoaded(true);

    } else if (msg && msg.includes("success")) {
      setStudentNotFound(false);
      setLoaded(true);
    }
  }, [msg]);
  useEffect(() => {
    if (voucherMsg && voucherMsg.includes('Success')) {
      dispatch(resetAdmissionState())
      dispatch(resetVoucher())
      alert(voucherMsg)
      navigate(-1)
    }
    else if (voucherMsg && voucherMsg.includes('Failed')) {
      dispatch(resetVoucher())
      alert(voucherMsg)
    }
  }, [voucherMsg]);

  const handleRollNumberInput = (e) => {
    const newRollNo = e.target.value;
    setRollNo(newRollNo);
  };
  useEffect(() => {
    if (
      selectedClass !== "" &&
      selectedSection !== "" &&
      examinationFee !== ""
    ) {
      setAllowSubmit(true);
    } else {
      setAllowSubmit(false);
    }
  }, [selectedClass, selectedSection, examinationFee]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedClass === "" && rollNo === "" && selectedSection) {
      alert("Please select a class and section or enter student roll number to apply charges")
      return
    }
    dispatch(startLoading())
    const data = {
      examinationFee: examinationFee,
      note: note,
      ofClass: selectedClass ? selectedClass : Admission.studentMonthlyFeeInfo.studentClass,
      section: selectedSection ? selectedSection : Admission.studentMonthlyFeeInfo.section,
      rollNumber: rollNo,
    };
    dispatch(addAdditionalCharges(data));
    setRollNo("");
    setSelectedClass("");
    setSelectedSection("");
    setExaminationFee("");
    setNote("");
    setAllowSubmit(false);
    setLoaded(false);
    setStudentNotFound(false);
    dispatch(resetAdmissionState());
  };
  return (
    <div className="flex flex-col items-start justify-center px-4">
      {(loading || admissionLoading) && <Loader />}

      <Header />
      <div className="flex flex-row items-center justify-start w-full border-b border-solid border-[#DDDDDD] py-2">
        <h1 className="text-3xl font-bold text-center text-gray-800">
          Additional Charges
        </h1>
        <h2 className="ml-4 text-xl font-semibold text-center text-gray-500">
          {User.campus} Campus
        </h2>
      </div>
      <div className="flex flex-col items-center justify-center w-full ">
        <form className="flex flex-row items-center justify-center gap-4 mt-2 border-b border-solid border-[#DDDDDD] py-2">
          <div className="flex flex-col items-center justify-center  gap-2">
            <label className="text-base font-bold text-gray-800">Class</label>
            <select
              className="px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
              value={selectedClass}
              onChange={(e) => setSelectedClass(e.target.value)}
            >
              <option value="">Select Class</option>
              {classes.map((item, index) => (
                <option value={item} key={index}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col items-center justify-center  gap-2">
            <label className="text-base font-bold text-gray-800">Section</label>
            <select
              className="px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
              value={selectedSection}
              onChange={(e) => setSelectedSection(e.target.value)}
            >
              <option value="">Select Section</option>
              {sections.map((item, index) => (
                <option value={item} key={index}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </form>
        <div className="flex flex-row items-center justify-center gap-2 border-b border-solid border-[#DDDDDD] py-2">
          <div className="flex flex-col items-center justify-center">
            <h1 className="text-base font-bold text-gray-800">Roll Number</h1>
            <input
              className="w-full px-4 py-2 mt-2 border border-solid border-[#DDDDDD] rounded outline-none"
              type="text"
              name="rollNo"
              id="rollNo"
              value={rollNo}
              onChange={handleRollNumberInput}
              placeholder="Empty for all students"
            />
          </div>
          {loaded ? (
            studentNotFound ? (
              <h1 className="text-xl font-bold text-red-500 mt-[30px]">
                Student not found
              </h1>
            ) : (
              <h1 className="text-xl font-bold text-green-500 mt-[30px]">
                Student found
              </h1>
            )
          ) : null}
        </div>
        <form className="flex flex-row items-center justify-center gap-4 mt-2 border-b border-solid border-[#DDDDDD] py-2">
          <div className="flex flex-col items-center justify-center w-full gap-2">
            <label className="text-base font-bold text-gray-800">
              Examination Fee
            </label>
            <input
              type="number"
              className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
              placeholder="Examination Fee"
              value={examinationFee}
              onChange={(e) => setExaminationFee(e.target.value)}
            />
          </div>
          <div className="flex flex-col items-center justify-center w-full gap-2">
            <label className="text-base font-bold text-gray-800">Note</label>
            <input
              type="text"
              className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
              placeholder="Note"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </div>
        </form>
        {
          Object.keys(voucherData).length > 0 && loaded ?
            <table className="min-w-full border border-solid border-[#CCCCCC] mt-3">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-base font-bold text-gray-800">
                    Roll Number
                  </th>
                  <th className="px-4 py-2 text-base font-bold text-gray-800">
                    Name
                  </th>
                  <th className="px-4 py-2 text-base font-bold text-gray-800">
                    Class
                  </th>
                  <th className="px-4 py-2 text-base font-bold text-gray-800">
                    Section
                  </th>
                  <th className="px-4 py-2 text-base font-bold text-gray-800">
                    Voucher Id
                  </th>
                  <th className="px-4 py-2 text-base font-bold text-gray-800">
                    Fee Period
                  </th>
                  <th className="px-4 py-2 text-base font-bold text-gray-800">
                    Issue date
                  </th>
                  <th className="px-4 py-2 text-base font-bold text-gray-800">
                    Monthly Fee
                  </th>
                  <th className="px-4 py-2 text-base font-bold text-gray-800">
                    Registration Fee
                  </th>
                  <th className="px-4 py-2 text-base font-bold text-gray-800">
                    Admission Fee
                  </th>
                  <th className="px-4 py-2 text-base font-bold text-gray-800">
                    Examination Fee
                  </th>
                  <th className="px-4 py-2 text-base font-bold text-gray-800">
                    Discount
                  </th>
                  <th className="px-4 py-2 text-base font-bold text-gray-800">
                    Arrears
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center">
                    {Admission.studentMonthlyFeeInfo.rollNo}
                  </td>
                  <td className="text-center">
                    {Admission.studentMonthlyFeeInfo.studentName}
                  </td>
                  <td className="text-center">
                    {Admission.studentMonthlyFeeInfo.studentClass}
                  </td>
                  <td className="text-center">
                    {Admission.studentMonthlyFeeInfo.section}
                  </td>
                  <td className="text-center">
                    {voucherData.voucherNo}
                  </td>
                  <td className="text-center">
                    {voucherData.feePeriod}

                  </td>
                  <td className="text-center">
                    {voucherData.issueDate}
                  </td>
                  <td className="text-center">
                    {voucherData.monthlyFee}
                  </td>
                  <td className="text-center">
                    {voucherData.registrationFee}
                  </td>
                  <td className="text-center">
                    {voucherData.admissionFee}
                  </td>
                  <td className="text-center">
                    {voucherData.examinationFee}
                  </td>
                  <td className="text-center">
                    {voucherData.discount}
                  </td>
                  <td className="text-center">
                    {voucherData.arrears}
                  </td>
                </tr>
              </tbody>
            </table>
            : null
        }

        <div className="flex flex-row items-center justify-center w-full gap-4 mt-4">
          <button
            className={
              allowSubmit
                ? "px-4 py-2 text-base font-bold text-white bg-green-900 rounded-md"
                : "px-4 py-2 text-base font-bold text-white bg-gray-500 rounded-md hover:cursor-not-allowed"
            }
            onClick={handleSubmit}
          >
            Save
          </button>
          <button
            className="px-4 py-2 text-base font-bold text-white bg-red-500 rounded-md"
            onClick={() => navigate(-1)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdditionalCharges;
