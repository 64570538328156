import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  startLoading as startAdmissionLoading,
  getStudentByRollNo,
  resetAdmissionState,
} from "../../actions/admissionActions";

import {
  generateVoucherCampus,
  generateVoucherStudent,
  resetVoucher,
  startLoading
} from "../../actions/voucherActions";
import Header from "../header/Header";
import Loader from "../../components/Loader";

const GenerateVoucherCampus = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const User = useSelector((state) => state.user);
  //////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (!User.isSignedIn) {
      navigate("/login");
    }
  }, [User, navigate]);
  //////////////////////////////////////////////////////////////////////////
  const Admission = useSelector((state) => state.admission);
  const Voucher = useSelector((state) => state.voucher);
  const loading = Voucher.loading;
  const admissionLoading = Admission.loading;
  const msg = Admission.msg;
  const voucherMsg = Voucher.msg;
  const [issueDate, setIssueDate] = useState("");
  const [dueDate, setDueDate] = useState("");

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [note, setNote] = useState("");
  // student voucher data
  const [rollNo, setRollNo] = useState("");
  const [studentNotFound, setStudentNotFound] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [isStudentPage, setIsStudentPage] = useState(false);

  useEffect(() => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const initialDate = `${year}-${month}-${day}`;
    setIssueDate(initialDate);

    // Set due date 10 days after issue date
    const issueDateObject = new Date(initialDate);
    issueDateObject.setDate(issueDateObject.getDate() + 10);

    const dueDay = String(issueDateObject.getDate()).padStart(2, "0");
    const dueMonth = String(issueDateObject.getMonth() + 1).padStart(2, "0");
    const dueYear = issueDateObject.getFullYear();
    setDueDate(`${dueYear}-${dueMonth}-${dueDay}`);

    // Set from date to 1st of current month
    const fromDateObject = new Date(initialDate);
    fromDateObject.setDate(1);

    const fromDay = String(fromDateObject.getDate()).padStart(2, "0");
    const fromMonth = String(fromDateObject.getMonth() + 1).padStart(2, "0");
    const fromYear = fromDateObject.getFullYear();
    setFromDate(`${fromYear}-${fromMonth}-${fromDay}`);

    // Set date to the last day of the current month
    const toDateObject = new Date();
    toDateObject.setMonth(toDateObject.getMonth() + 1, 0); // Set to the last day of the current month

    const toDay = String(toDateObject.getDate()).padStart(2, "0");
    const toMonth = String(toDateObject.getMonth() + 1).padStart(2, "0");
    const toYear = toDateObject.getFullYear();
    setToDate(`${toYear}-${toMonth}-${toDay}`);
  }, []);

  useEffect(() => {
    let timeoutId;
    const fetchStudentData = () => {
      if (rollNo !== "") {
        dispatch(startAdmissionLoading());
        dispatch(getStudentByRollNo(rollNo, User.campus));
      }
    };
    // Set a timeout to delay the function call
    timeoutId = setTimeout(fetchStudentData, 500); // Adjust the delay time as needed
    // Clear the previous timeout if the rollNo changes before it fires
    return () => clearTimeout(timeoutId);
  }, [rollNo, dispatch]);

  useEffect(() => {
    // check if url contains /student
    if (location.pathname.includes("/student")) {
      setIsStudentPage(true);
    }
  }, []);

  useEffect(() => {
    if (msg === "Student not found" || msg === "Student does not exist") {
      setStudentNotFound(true);
      setLoaded(true);

    } else if (msg && msg.includes("success")) {
      setStudentNotFound(false);
      setLoaded(true);
    }
  }, [msg]);

  useEffect(() => {
    if (voucherMsg && voucherMsg.includes('successfully')) {
      dispatch(resetAdmissionState())
      dispatch(resetVoucher())
      alert(voucherMsg)
      navigate(-1)
    }
    else if (voucherMsg && (voucherMsg.includes('Failed') || voucherMsg.includes('already exists'))) {
      dispatch(resetVoucher())
      alert(voucherMsg)
    }
  }, [voucherMsg]);

  const handleRollNumberInput = (e) => {
    const newRollNo = e.target.value;
    setRollNo(newRollNo);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(startLoading())
    if (isStudentPage) {
      const data = {
        voucherData: Admission.studentMonthlyFeeInfo,
        studentData: Admission.studentPersonalInfo,
        issueDate: issueDate,
        dueDate: dueDate,
        note: note,
        fromDate: fromDate,
        toDate: toDate
      };
      dispatch(generateVoucherStudent(rollNo, data));
    } else {
      dispatch(
        generateVoucherCampus(
          User.campus,
          {
            fromDate,
            toDate,
            issueDate,
            dueDate,
            note
          }
        )
      );
    }
  };

  return (
    <div className="flex flex-col items-start justify-center px-4">
      {(loading || admissionLoading) && <Loader />}

      <Header />

      <div className="flex flex-row items-center justify-start w-full border-b border-solid border-[#DDDDDD] py-2">
        <h1 className="text-3xl font-bold text-center text-gray-800">
          Generate Voucher {isStudentPage ? "for Student" : "for Campus"}
        </h1>
        <h2 className="ml-4 text-xl font-semibold text-center text-gray-500">
          {User.campus} Campus
        </h2>
      </div>
      {isStudentPage ? (
        <div className="flex flex-row items-center justify-center w-full mt-4 gap-2">
          <div className="flex flex-col items-center justify-center">
            <h1 className="text-base font-bold text-gray-800">Roll Number</h1>
            <input
              className="w-full px-4 py-2 mt-2 border border-solid border-[#DDDDDD] rounded outline-none"
              type="text"
              name="rollNo"
              id="rollNo"
              value={rollNo}
              onChange={handleRollNumberInput}
            />
          </div>
          {loaded ? (
            studentNotFound ? (
              <h1 className="text-xl font-bold text-red-500 mt-[30px]">
                Student not found
              </h1>
            ) : (
              <h1 className="text-xl font-bold text-green-500 mt-[30px]">
                Student found
              </h1>
            )
          ) : null}
        </div>
      ) : null}

      <div className="flex flex-row items-center justify-center w-full mt-4">
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-base font-bold text-gray-800">Issue Date</h1>
          <input
            className="w-full px-4 py-2 mt-2 border border-solid border-[#DDDDDD] rounded outline-none"
            type="date"
            name="issueDate"
            id="issueDate"
            value={issueDate}
            onChange={(e) => setIssueDate(e.target.value)}
          />
        </div>
        <div className="flex flex-col items-center justify-center ml-4">
          <h1 className="text-base font-bold text-gray-800">Due Date</h1>
          <input
            className="w-full px-4 py-2 mt-2 border border-solid border-[#DDDDDD] rounded outline-none"
            type="date"
            name="dueDate"
            id="dueDate"
            value={dueDate}
            onChange={(e) => setDueDate(e.target.value)}
          />
        </div>
      </div>
      <div className="flex flex-row items-center justify-center w-full mt-4">
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-base font-bold text-gray-800">From Date</h1>
          <input
            className="w-full px-4 py-2 mt-2 border border-solid border-[#DDDDDD] rounded outline-none"
            type="date"
            name="fromDate"
            id="fromDate"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
          />
        </div>
        <div className="flex flex-col items-center justify-center ml-4">
          <h1 className="text-base font-bold text-gray-800">To Date</h1>
          <input
            className="w-full px-4 py-2 mt-2 border border-solid border-[#DDDDDD] rounded outline-none"
            type="date"
            name="toDate"
            id="toDate"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
          />
        </div>
      </div>
      <div className="flex flex-col items-start justify-start w-full mt-4">
        <h1 className="text-base font-bold text-gray-800">Note</h1>
        <textarea
          className="w-full px-4 py-2 mt-2 border border-solid border-[#DDDDDD] rounded outline-none"
          name="note"
          id="note"
          cols="30"
          rows="3"
          value={note}
          onChange={(e) => setNote(e.target.value)}
        ></textarea>
      </div>
      <div className="flex flex-row items-center justify-center w-full gap-4 mt-4">
        <button
          className={
            true
              ? "px-4 py-2 text-base font-bold text-white bg-green-900 rounded-md"
              : "px-4 py-2 text-base font-bold text-white bg-gray-500 rounded-md hover:cursor-not-allowed"
          }
          onClick={handleSubmit}
        >
          Generate Voucher
        </button>

        <button
          className="px-4 py-2 text-base font-bold text-white bg-red-500 rounded-md"
          onClick={() => navigate(-1)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default GenerateVoucherCampus;
