import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Login from "./pages/login/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import NewAdmissions from "./pages/admissions/NewAdmissions";
import Promotions from "./pages/admissions/Promotions";
import StudentRemoval from "./pages/admissions/StudentRemoval";
import StudentFeeChange from "./pages/admissions/StudentFeeChange";
import GenerateVoucherCampus from "./pages/vouchers/GenerateVoucherCampus";
import AdditionalCharges from "./pages/vouchers/AdditionalCharges";
import PostVouchers from "./pages/vouchers/PostVouchers";
import UnPostVouchers from "./pages/vouchers/UnPostVouchers";
import IndividualReceipts from "./pages/receipts/IndividualReceipts";
import EditIndividualReceipts from "./pages/receipts/EditIndividualReceipts";
import StudentList from "./pages/admissionReports/StudentList";
import AdmissionReports from "./pages/admissionReports/AdmissionReports";
import Authenticatore from "./components/Authenticatore";
import FeeVouchers from "./pages/feeReports/FeeVouchers";
import FeeReceived from "./pages/feeReports/FeeReceived";
import FeeRecord from "./pages/feeReports/FeeRecord";
import NetReceivables from "./pages/feeReports/NetReceivables";
import PrintVouchers from "./pages/feeReports/PrintVouchers";
import EditAdmissions from "./pages/admissions/EditAdmissions";
import EditVouchers from "./pages/admissions/EditVouchers";
// FIX DATE FORMAT DD-MM-YYYY
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Authenticatore />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/new-admissions" element={<NewAdmissions />} />
        <Route path="/edit-admissions" element={<EditAdmissions />} />
        <Route path="/promotions" element={<Promotions />} />
        <Route path="/graduation" element={<Promotions />} />
        <Route path="/student-removal" element={<StudentRemoval />} />
        <Route path="/student-fee-change" element={<StudentFeeChange />} />
        <Route
          path="/generate-voucher/campus"
          element={<GenerateVoucherCampus />}
        />
        <Route
          path="/generate-voucher/student"
          element={<GenerateVoucherCampus />}
        />
        <Route path="/additional-charges" element={<AdditionalCharges />} />
        <Route path="/post-vouchers" element={<PostVouchers />} />
        <Route path="/unpost-vouchers" element={<UnPostVouchers />} />
        <Route path="/receipts/individual" element={<IndividualReceipts />} />
        <Route
          path="/receipts/individual/edit"
          element={<EditIndividualReceipts />}
        />
        <Route path="/student-list" element={<StudentList />} />
        <Route path="/admission-reports" element={<AdmissionReports />} />
        <Route path="/fee-vouchers" element={<FeeVouchers />} />
        <Route path="/fee-received" element={<FeeReceived />} />
        <Route path="/fee-record" element={<FeeRecord />} />
        <Route path="/net-receivables" element={<NetReceivables />} />
        <Route path="/print-vouchers" element={<PrintVouchers />} />
        <Route path="/edit-vouchers" element={<EditVouchers />} />
      </Routes>
    </Router>
  );
}

export default App;
