const classes = [
  "Play Group",
  "Nursery",
  "Prep",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "Hifz"
];
const sections = ["Red", "Green", "Blue", "Pink", "Rose", "Hifz"];

export { classes, sections };
