import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  checkUnpostedVouchers,
  unpostVouchers,
  startLoading,
  resetVoucher,
  getPostedVouchers
} from "../../actions/voucherActions";
import { useNavigate } from "react-router-dom";
import Header from "../header/Header";
import Loader from "../../components/Loader";

const UnPostVouchers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const vouchers = useSelector((state) => state.voucher);

  const unpostedVouchers = vouchers.unpostedVouchers;

  const User = useSelector((state) => state.user);
  const months = [
    "January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"
  ]
  const [leftMonths, setLeftMonths] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [unPosted, setUnposted] = useState(false);
  //////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (!User.isSignedIn) {
      navigate("/login");
    }
  }, [User, navigate]);
  //////////////////////////////////////////////////////////////////////////

  // useEffect(() => {
  //   dispatch(checkUnpostedVouchers(User.campus));
  // }, []);

  const handleUnPost = () => {
    const index = months.indexOf(selectedMonth)

    dispatch(unpostVouchers(User.campus, index + 1));
    dispatch(startLoading());
  };

  useEffect(() => {
    if (vouchers.msg === "success") {
      setUnposted(true);
      dispatch(checkUnpostedVouchers(User.campus));
      dispatch(resetVoucher());
    }
  }, [vouchers.msg]);

  useEffect(() => {
    // get remaining months of the year
    const date = new Date();
    const currentMonth = date.getMonth();
    const remainingMonths = months.slice(currentMonth)
    setLeftMonths(remainingMonths)
  })
  const handleGetVouchers = () => {
    if (selectedMonth === "") {
      return alert("Please select a month.")
    }
    dispatch(startLoading());

    const index = months.indexOf(selectedMonth)
    dispatch(getPostedVouchers(User.campus, index + 1))
  }
  useEffect(() => {
    if (vouchers.msg === 'successfully fetched posted vouchers' && vouchers?.vouchers?.length === 0) {
      return alert("No posted vouchers found for the selected month.")
    }
  }, [vouchers.vouchers])
  return (
    <div className="flex flex-col items-start justify-center px-4">
      <Header />
      <div className="flex flex-row items-center justify-start w-full border-b border-solid border-[#DDDDDD] py-2">
        <h1 className="text-3xl font-bold text-center text-gray-800">
          Un-post Vouchers
        </h1>
        <h2 className="ml-4 text-xl font-semibold text-center text-gray-500">
          {User.campus} Campus
        </h2>
      </div>


      <div className="flex flex-col items-center justify-center w-full mt-4 font-sans font-medium text-sm text-black gap-4">
        <select
          className="w-1/2 px-4 py-2 text-base font-bold text-black bg-gray-100 rounded-md"
          name="month"
          id="month"
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(e.target.value)}
        >
          <option value="">Select Month</option>
          {leftMonths.map((month, index) => (
            <option key={index} value={month}>{month}</option>
          ))}
        </select>
        <button className="px-4 py-2 bg-blue-400 rounded-md" onClick={handleGetVouchers}> Get unposted vouchers</button>
      </div>
      <div className="flex flex-col items-center justify-center w-full mt-4 font-sans font-medium text-sm text-black">
        {
          vouchers?.vouchers?.length > 0 ? (
            <table className="min-w-full border border-solid border-[#CCCCCC] mt-3">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-base font-bold text-gray-800">
                    Roll Number
                  </th>
                  <th className="px-4 py-2 text-base font-bold text-gray-800">
                    Name
                  </th>
                  <th className="px-4 py-2 text-base font-bold text-gray-800">
                    Class
                  </th>
                  <th className="px-4 py-2 text-base font-bold text-gray-800">
                    Section
                  </th>
                  <th className="px-4 py-2 text-base font-bold text-gray-800">
                    Voucher Id
                  </th>
                  <th className="px-4 py-2 text-base font-bold text-gray-800">
                    Fee Period
                  </th>
                  <th className="px-4 py-2 text-base font-bold text-gray-800">
                    Issue date
                  </th>
                  <th className="px-4 py-2 text-base font-bold text-gray-800">
                    Monthly Fee
                  </th>
                  <th className="px-4 py-2 text-base font-bold text-gray-800">
                    Registration Fee
                  </th>
                  <th className="px-4 py-2 text-base font-bold text-gray-800">
                    Admission Fee
                  </th>
                  <th className="px-4 py-2 text-base font-bold text-gray-800">
                    Examination Fee
                  </th>
                  <th className="px-4 py-2 text-base font-bold text-gray-800">
                    Discount
                  </th>
                  <th className="px-4 py-2 text-base font-bold text-gray-800">
                    Arrears
                  </th>
                </tr>
              </thead>
              <tbody>
                {vouchers.vouchers.map((voucher, index) => (

                  <tr>
                    <td className="text-center">
                      {voucher.rollNo}
                    </td>
                    <td className="text-center">
                      {voucher.studentName}
                    </td>
                    <td className="text-center">
                      {voucher.studentClass}
                    </td>
                    <td className="text-center">
                      {voucher.section}
                    </td>
                    <td className="text-center">
                      {voucher.voucherNo}
                    </td>
                    <td className="text-center">
                      {voucher.feePeriod}

                    </td>
                    <td className="text-center">
                      {voucher.issueDate}
                    </td>
                    <td className="text-center">
                      {voucher.monthlyFee}
                    </td>
                    <td className="text-center">
                      {voucher.registrationFee}
                    </td>
                    <td className="text-center">
                      {voucher.admissionFee}
                    </td>
                    <td className="text-center">
                      {voucher.examinationFee}
                    </td>
                    <td className="text-center">
                      {voucher.discount}
                    </td>
                    <td className="text-center">
                      {voucher.arrears}
                    </td>
                  </tr>
                ))}

              </tbody>
            </table>
          ) : null
        }
      </div>

      <div className="flex flex-col items-center justify-center w-full ">
        <div className="flex flex-row items-center justify-center w-full gap-4 mt-4">
          <button
            className={
              vouchers?.vouchers?.length > 0
                ? "px-4 py-2 text-base font-bold text-white bg-green-900 rounded-md hover:cursor-pointer"
                : "px-4 py-2 text-base font-bold text-white bg-gray-500 rounded-md hover:cursor-not-allowed"
            }
            onClick={handleUnPost}
            disabled={vouchers?.vouchers?.length === 0}
          >
            Un-post
          </button>
          <button
            className="px-4 py-2 text-base font-bold text-white bg-red-500 rounded-md"
            onClick={() => {
              dispatch(resetVoucher())
              navigate(-1)
            }}
          >
            Cancel
          </button>
        </div>
      </div>
      {vouchers.loading && <Loader />}
    </div>
  );
};

export default UnPostVouchers;
