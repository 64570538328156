import React from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import { classes } from '../../consts/commons';

const Graph = ({ data }) => {
    var CanvasJSChart = CanvasJSReact.CanvasJSChart;
    const { classwiseDataAdmitted, classWiseDataRemoved, dataType } = data;

    let dataArray = [];
    let finalTitle = "";

    if (dataType === "admissions") {
        finalTitle = "Admissions";
        for (let i = 0; i < classes.length; i++) {
            const className = classes[i];
            const admittedCount = classwiseDataAdmitted[className] || 0;
            dataArray.push({ y: admittedCount, label: className, type: "admitted" });
        }
    } else if (dataType === "removals") {
        finalTitle = "Students Left";
        for (let i = 0; i < classes.length; i++) {
            const className = classes[i];
            const removedCount = classWiseDataRemoved[className] || 0;
            dataArray.push({ y: removedCount, label: className, type: "removed" });
        }
    } else if (dataType === "all") {
        finalTitle = "Admissions and Students Left";
        for (let i = 0; i < classes.length; i++) {
            const className = classes[i];
            const admittedCount = classwiseDataAdmitted[className] || 0;
            const removedCount = classWiseDataRemoved[className] || 0;
            dataArray.push({ y: admittedCount, y2: removedCount, label: className });
        }
    }

    const options = {
        animationEnabled: true,
        title: { text: finalTitle },
        axisY: { title: `Number of ${finalTitle}`, includeZero: false },
        axisX: { title: "Class", interval: 1 },
        data: [
            {
                type: "stackedColumn",
                name: "Admitted",
                showInLegend: true,
                dataPoints: dataArray.map(item => ({ y: item.y, label: item.label, color: "green", type: item.type }))
            },
            {
                type: "stackedColumn",
                name: "Removed",
                showInLegend: true,
                dataPoints: dataArray.map(item => ({ y: item.y2 || 0, label: item.label, color: "red", type: item.type }))
            }
        ]
    };

    return (
        <div className='w-full'>
            <CanvasJSChart options={options} />
        </div>
    );
}

export default Graph;
