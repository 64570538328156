const initialState = {
  vouchers: [],
  loading: false,
  failed: false,
  msg: null,
  unpostedVouchers: false,
};

const voucherReducer = (state = initialState, action) => {

  switch (action.type) {
    case "GENERATE_VOUCHER_CAMPUS":
      return {
        ...state,
        loading: false,
        failed: false,
        msg: action.payload.msg,
      };

    case "GENERATE_VOUCHER_FAILED":
      return {
        ...state,
        failed: true,
        loading: false,
        msg: action.payload.msg,
      };
    case "START_VOUCHER_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "GENERATE_VOUCHER_STUDENT":
      return {
        ...state,
        loading: false,
        failed: false,
        msg: action.payload.msg,
      };
    case "ADD_ADDITIONAL_CHARGES":
      return {
        ...state,
        loading: false,
        failed: false,
        msg: "Successfully added additional charges",
      };
    case "ADD_ADDITIONAL_CHARGES_FAILED":
      return {
        ...state,
        failed: true,
        loading: false,
        msg: action.payload.msg,
      };
    case "CHECK_UNPOSTED_VOUCHERS":
      return {
        ...state,
        loading: false,
        failed: false,
        msg: null,
        unpostedVouchers: action.payload.msg,
      };
    case "CHECK_UNPOSTED_VOUCHERS_FAILED":
      return {
        ...state,
        failed: true,
        loading: false,
        msg: action.payload.msg,
      };
    case "RESET_VOUCHER":
      return {
        vouchers: [],
        loading: false,
        failed: false,
        msg: null,
        unpostedVouchers: false,
      };
    case "POST_VOUCHERS":
      return {
        ...state,
        loading: false,
        failed: false,
        msg: "success",
      };
    case "POST_VOUCHERS_FAILED":
      return {
        ...state,
        failed: true,
        loading: false,
        msg: action.payload.msg,
      };
    case "UNPOST_VOUCHERS":
      return {
        ...state,
        loading: false,
        failed: false,
        msg: "success",
      };
    case "UNPOST_VOUCHERS_FAILED":
      return {
        ...state,
        failed: true,
        loading: false,
        msg: action.payload.msg,
      };
    case "GET_INDIVIDUAL_VOUCHER":
      return {
        ...state,
        loading: false,
        failed: false,
        msg: action.payload.msg,
        vouchers: action.payload.data,
      };
    case "GET_INDIVIDUAL_VOUCHER_FAILED":
      return {
        ...state,
        failed: true,
        loading: false,
        msg: action.payload.msg,
      };
    case "PAY_INDIVIDUAL_VOUCHER":
      return {
        ...state,
        loading: false,
        failed: false,
        msg: "Voucher Paid",
      };
    case "PAY_INDIVIDUAL_VOUCHER_FAILED":
      return {
        ...state,
        failed: true,
        loading: false,
        msg: action.payload.msg,
      };
    case "GET_INDIVIDUAL_ALL_VOUCHER":
      return {
        ...state,
        loading: false,
        failed: false,
        msg: action.payload.msg,
        vouchers: action.payload.data,
      };
    case "GET_INDIVIDUAL_ALL_VOUCHER_FAILED":
      return {
        ...state,
        failed: true,
        loading: false,
        msg: action.payload.msg,
      };
    case "GET_ALL_VOUCHERS":
      return {
        ...state,
        loading: false,
        failed: false,
        msg: action.payload.msg,
        vouchers: action.payload.data,
      };
    case "GET_ALL_VOUCHERS_FAILED":
      return {
        ...state,
        failed: true,
        loading: false,
        msg: "Failed to get vouchers",
      };
    case "GET_UNPOSTED_VOUCHERS":
      return {
        ...state,
        loading: false,
        failed: false,
        msg: "successfully fetched un posted vouchers",
        vouchers: action.payload.data ? action.payload.data : [],
      }
    case "GET_UNPOSTED_VOUCHERS_FAILED":
      return {
        ...state,
        failed: true,
        loading: false,
        msg: "Failed to get unposted vouchers",
      }
    case "GET_POSTED_VOUCHERS":
      return {
        ...state,
        loading: false,
        failed: false,
        msg: "successfully fetched posted vouchers",
        vouchers: action.payload.data ? action.payload.data : [],
      }
    case "GET_POSTED_VOUCHERS_FAILED":
      return {
        ...state,
        failed: true,
        loading: false,
        msg: "Failed to get posted vouchers",
      }
    default:
      return state;
  }
};

export default voucherReducer;
