const initialState = {
  admission: null,
  loading: false,
  failed: false,
  msg: null,
  lastRollNo: null,
  studentPersonalInfo: null,
  studentMonthlyFeeInfo: null,
  studentList: [],
  admissionReports: []
};

const admissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_LAST_ROLL_NO":
      return {
        ...state,
        lastRollNo: action.payload.data,
        loading: false,
        failed: false,
        msg: null,
      };
    case "POST_ADMISSION":
      return {
        ...state,
        admission: action.payload.admission,
        loading: false,
        failed: false,
        msg: "Admission added successfully",
      };
    case "POST_ADMISSION_FAILED":
      return {
        ...state,
        failed: true,
        loading: false,
        msg: "Admission failed, please try again",
      };

    case "START_ADMISSION_LOADING":
      return {
        ...state,
        loading: true,
      };

    case "PROMOTE_CLASS":
      return {
        ...state,
        loading: false,
        failed: false,
        msg: action.payload.msg,
      };
    case "PROMOTE_CLASS_FAILED":
      return {
        ...state,
        failed: true,
        loading: false,
        msg: action.payload.response.data.msg,
      };
    case "GET_STUDENT_BY_ROLL_NO":
      if (action.payload.msg === "Student has graduated") {
        alert("Student has graduated");
        return {
          admission: null,
          loading: false,
          failed: false,
          msg: null,
          lastRollNo: null,
          studentPersonalInfo: null,
          studentMonthlyFeeInfo: null,
        };
      }
      return {
        ...state,
        studentPersonalInfo: action.payload.data?.studentData ?? null,
        studentMonthlyFeeInfo: action.payload.data?.monthlyFeeData ?? null,
        loading: false,
        failed: false,
        msg: action.payload.msg,
      };
    case "GET_STUDENT_BY_ROLL_NO_FAILED":
      return {
        ...state,
        failed: true,
        loading: false,
        msg: action.payload.response.data.msg,
      };
    case "REMOVE_STUDENT":
      return {
        ...state,
        loading: false,
        failed: false,
        msg: "Student removed successfully",
      };
    case "REMOVE_STUDENT_FAILED":
      return {
        ...state,
        failed: true,
        loading: false,
        msg: action.payload.msg,
      };
    case "CHANGE_STUDENT_FEE":
      return {
        ...state,
        loading: false,
        failed: false,
        msg: "Student fee changed successfully",
      };
    case "CHANGE_STUDENT_FEE_FAILED":
      return {
        ...state,
        failed: true,
        loading: false,
        msg: action.payload.msg,
      };
    case "RESET_ADMISSION_STATE":
      return {
        ...state,
        admission: null,
        loading: false,
        failed: false,
        msg: null,
        lastRollNo: null,
        studentPersonalInfo: null,
        studentMonthlyFeeInfo: null,
        admissionReports: [],
        studentList: [],
      };
    case "MARK_ARREARS_PAID": {
      return {
        ...state,
        loading: false,
        failed: false,
        msg: "Arrears marked as paid successfully",
      };
    }
    case "MARK_ARREARS_PAID_FAILED": {
      return {
        ...state,
        loading: false,
        failed: true,
        msg: action.payload.response.data.msg,
      };
    }
    case "GET_STUDENT_LIST":
      return {
        ...state,
        studentList: action.payload.data,
        loading: false,
        failed: false,
        msg: action.payload.msg,
      };
    case "GET_STUDENT_LIST_FAILED":
      return {
        ...state,
        failed: true,
        loading: false,
        msg: "Getting student list failed",
        studentList: [],
      };
    case "GET_ADMISSION_REPORTS":
      return {
        ...state,
        admissionReports: action.payload.data,
        loading: false,
        failed: false,
        msg: action.payload.msg,
      };
    case "GET_ADMISSION_REPORTS_FAILED":
      return {
        ...state,
        failed: true,
        loading: false,
        msg: "Getting admission reports failed",
        admissionReports: null,
      };
    case "EDIT_ADMISSION":
      return {
        ...state,
        admission: action.payload.admission,
        loading: false,
        failed: false,
        msg: "Admission edited successfully",
      };
    case "EDIT_ADMISSION_FAILED":
      return {
        ...state,
        failed: true,
        loading: false,
        msg: "Editing admission failed",
      };
    case "EDIT_VOUCHER":
      return {
        ...state,
        loading: false,
        failed: false,
        msg: "Voucher edited successfully",
      };
    case "EDIT_VOUCHER_FAILED":
      return {
        ...state,
        failed: true,
        loading: false,
        msg: "Editing voucher failed",
      };
    default:
      return state;
  }
};

export default admissionReducer;
