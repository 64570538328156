import React from "react";
import { SpinnerCircular } from "spinners-react";

const Loader = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full z-9999 bg-opacity-50 bg-black">
      <div className="flex flex-col items-center justify-center w-full h-full">
        <SpinnerCircular
          size={80}
          thickness={100}
          speed={100}
          color="#ffffff"
          secondaryColor="rgb(239 68 68)"
        />
        <p className="text-white font-sans font-semibold text-base">Loading...</p>
      </div>
    </div>
  );
};

export default Loader;
