import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "../header/Header";
import Loader from "../../components/Loader";
import { classes, sections } from "../../consts/commons";
import { getStudentList, startLoading } from "../../actions/admissionActions";
import { useNavigate } from "react-router-dom";
const StudentList = () => {
    const User = useSelector((state) => state.user);
    const Admission = useSelector((state) => state.admission);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    //////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (!User.isSignedIn) {
            navigate("/login");
        }
    }, [User, navigate]);
    //////////////////////////////////////////////////////////////////////////
    // 
    const admissionLoading = Admission.loading;
    const studentList = Admission.studentList;
    const [className, setClassName] = useState("all");
    const [section, setSection] = useState("all");
    const [totalStudents, setTotalStudents] = useState(0);

    useEffect(() => {
        if (studentList) {
            setTotalStudents(studentList.length);
        }
    }, [studentList]);


    const getAll = () => {
        dispatch(startLoading());
        dispatch(getStudentList("all", "all", User.campus));
    }
    const getStudents = () => {
        dispatch(startLoading());
        dispatch(getStudentList(className, section, User.campus));
    }

    useEffect(() => {
        if (Admission.msg && !Admission.msg.includes("fetched")) {
            alert(Admission.msg);
        }
    }
        , [Admission.msg]);
    return (
        <div className="flex flex-col items-start justify-center px-4">
            {(admissionLoading) && <Loader />}
            <Header />
            <div className="flex flex-row items-center justify-start w-full border-b border-solid border-[#DDDDDD] py-2">
                <h1 className="text-3xl font-bold text-center text-gray-800">
                    Student List
                </h1>
                <h2 className="ml-4 text-xl font-semibold text-center text-gray-500">
                    {User.campus} Campus
                </h2>
            </div>
            <div className="flex flex-row items-center justify-between w-full mt-4 gap-4">
                <div className="flex flex-row items-center justify-start w-full gap-4">
                    <div className="flex flex-col items-start justify-start w-full gap-1">
                        <label className="text-sm font-semibold text-gray-700">
                            Class
                        </label>
                        <select
                            className="w-full px-2 py-2 border border-solid border-[#CCCCCC] rounded"
                            value={className}
                            onChange={(e) => setClassName(e.target.value)}
                        >
                            <option value="">Select Class</option>
                            {classes.map((item, index) => (
                                <option key={index} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="flex flex-col items-start justify-start w-full gap-1">
                        <label className="text-sm font-semibold text-gray-700">
                            Section
                        </label>
                        <select
                            className="w-full px-2 py-2 border border-solid border-[#CCCCCC] rounded"
                            value={section}
                            onChange={(e) => setSection(e.target.value)}
                        >
                            <option value="">Select Section</option>
                            {sections.map((item, index) => (
                                <option key={index} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="flex flex-col items-start justify-start w-full gap-1" onClick={() => { getStudents() }}>
                        <label className="text-sm font-semibold text-gray-700">
                            &nbsp;
                        </label>
                        <button className="w-full px-4 py-2 text-sm font-semibold text-white bg-blue-500 rounded">
                            Get students
                        </button>
                    </div>
                </div>
            </div>
            {/* <div className="flex flex-row items-center justify-between w-full mt-4 gap-4">
                <div className="flex flex-col items-start justify-start w-[50%] gap-1">
                    <label className="text-sm font-semibold text-gray-700">
                        Search
                    </label>
                    <input
                        type="text"
                        className="w-full px-2 py-2 border border-solid border-[#CCCCCC] rounded"
                        placeholder="Search by Name, Roll No, Father Name"
                    />
                </div>
                <div className="flex flex-col items-start justify-start w-full gap-1">
                    <label className="text-sm font-semibold text-gray-700">
                        &nbsp;
                    </label>
                    <button className="w-full px-4 py-2 text-sm font-semibold text-white bg-[#FF5757] rounded">
                        Search
                    </button>
                </div>
            </div> */}
            <div className="flex flex-row items-center justify-between w-full mt-4 gap-4">
                {/* Button to get all students */}
                <div className="flex flex-col items-start justify-start w-[50%] gap-1" onClick={() => { getAll() }}>
                    <label className="text-sm font-semibold text-gray-700">
                        &nbsp;
                    </label>
                    <button className="w-full px-4 py-2 text-sm font-semibold text-white bg-cyan-700 rounded">
                        Get All Students
                    </button>
                </div>
            </div>
            <div className="flex flex-row items-center justify-between w-full mt-4 gap-4">
                {/* show count of total students */}
                <div className="flex flex-row items-center justify-start w-full gap-4">
                    <h1 className="text-xl font-semibold text-gray-600">
                        Total Students: {totalStudents}
                    </h1>
                </div>
            </div>
            {/* Back button */}
            <div className="flex flex-row items-center justify-between w-full mt-4 gap-4" onClick={() => { navigate(-1) }}>

                <button
                    className="px-4 py-2 text-base font-bold text-white bg-[#FF5757] rounded-md"
                >
                    Back
                </button>
            </div>
            <div className="flex flex-row items-start justify-between w-full mt-4 gap-4 ">
                <div className="flex flex-col items-center justify-center w-full mt-4">
                    <div className="overflow-x-auto w-full">
                        <table className="min-w-full border border-solid border-[#CCCCCC]">
                            <thead className="text-xs font-semibold uppercase bg-gray-200 text-gray-600">
                                <tr>
                                    <th className="px-2 py-3 text-center"> Roll no</th>
                                    <th className="px-2 py-3 text-center"> Name</th>
                                    <th className="px-2 py-3 text-center"> Gender </th>
                                    <th className="px-2 py-3 text-center"> Class</th>
                                    <th className="px-2 py-3 text-center"> Section</th>
                                    <th className="px-2 py-3 text-center"> Father Name</th>
                                    <th className="px-2 py-3 text-center"> Father Phone Home</th>
                                    <th className="px-2 py-3 text-center"> Office/Shop  Number
                                    </th>
                                    <th className="px-2 py-3 text-center"> Mother Phone Number</th>
                                    <th className="px-2 py-3 text-center"> Father Email</th>
                                    <th className="px-2 py-3 text-center"> Address</th>
                                    <th className="px-2 py-3 text-center"> Date of Birth</th>
                                    <th className="px-2 py-3 text-center"> Date of Admission</th>
                                    <th className="px-2 py-3 text-center"> Religion</th>


                                </tr>
                            </thead>
                            <tbody className="text-xs bg-white text-gray-600">
                                {studentList?.map((item, index) => (
                                    <tr key={index}>
                                        <td className="px-2 py-3 text-center">{item.rollNo}</td>
                                        <td className="px-2 py-3 text-center">{item.studentName}</td>
                                        <td className="px-2 py-3 text-center">{item.gender}</td>
                                        <td className="px-2 py-3 text-center">{item.studentClass}</td>
                                        <td className="px-2 py-3 text-center">{item.section}</td>
                                        <td className="px-2 py-3 text-center">{item.fatherName}</td>
                                        <td className="px-2 py-3 text-center">{item.fatherHomePhone}</td>
                                        <td className="px-2 py-3 text-center">{item.fatherOfficePhone}</td>
                                        <td className="px-2 py-3 text-center">{item.telephone}</td>
                                        <td className="px-2 py-3 text-center">{item.fatherEmail}</td>
                                        <td className="px-2 py-3 text-center">{item.address}</td>
                                        <td className="px-2 py-3 text-center">{item.dateOfBirth}</td>
                                        <td className="px-2 py-3 text-center">{item.admissionDate}</td>
                                        <td className="px-2 py-3 text-center">{item.religion}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default StudentList;
