import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../header/Header";
import { signIn, startLoading } from "../../actions/userActions";
import Loader from '../../components/Loader';
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const User = useSelector((state) => state.user);
  const { failed, isSignedIn } = User;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const submit = (e) => {
    e.preventDefault();
    dispatch(startLoading());
    dispatch(signIn(email, password));
  };

  useEffect(() => {
    if (isSignedIn) {
      navigate("/dashboard");
    }
  }, [isSignedIn]);

  useEffect(() => {
    if (failed) {
      setError(true);
    }
    const resetFunc = () => {
      setError(false);
    };
    setTimeout(resetFunc, 5000);
  }, [failed]);

  return (
    <div>
      {User.loading ? <Loader /> :
        <div className="flex-col items-center justify-center p-[312px] overflow-hidden">
          <div className="border border-[#DDDDDD] border-solid p-4 shadow-xl relative items-center justify-center flex flex-col">
            {error ? (
              <div className="absolute top-[128px] text-red-500 font-sans font-normal text-xs">
                Invalid credentials
              </div>
            ) : (
              <></>
            )}
            <Header fromLogin={true} />
            <div className="flex flex-col items-center justify-center  p-4 bg-white px-4">
              <form
                className="flex flex-col items-center justify-center"
                onSubmit={submit}
              >
                <input
                  className="rounded-md py-2 px-3 m-2 border border-black border-solid"
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  className="rounded-md py-2 px-3 m-2 border border-black border-solid"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button className="rounded-md py-2 px-2 m-2 bg-red-500 text-white">
                  Login
                </button>
              </form>
            </div>
          </div>
        </div>
      }
    </div>

  );
};

export default Login;
