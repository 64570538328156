import React from "react";
import { useNavigate } from "react-router-dom";

const Receipts = () => {
  const navigate = useNavigate();
  return (
    <div>
      {" "}
      <div
        className="p-4 hover:cursor-pointer hover:text-black"
        onClick={() => navigate("/receipts/individual")}
      >
        Individual fee receipts
      </div>
      {/* <div className=" p-4 hover:cursor-pointer hover:text-black">
        Class wise fee receipts
      </div> */}
      <div
        className=" p-4 hover:cursor-pointer hover:text-black"
        onClick={() => navigate("/receipts/individual/edit")}
      >
        Edit Individual fee receipts
      </div>
      {/* <div className=" p-4 hover:cursor-pointer hover:text-black">
        Edit Class wise fee receipts
      </div> */}
    </div>
  );
};

export default Receipts;
