import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signOut } from "../../../actions/userActions";
import { resetAdmissionState } from "../../../actions/admissionActions";
const UserModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const User = useSelector((state) => state.user);
  const handleSignOut = () => {
    dispatch(signOut());
    dispatch(resetAdmissionState());
    navigate("/");
  };
  return (
    <div className="absolute bg-white border border-[#DDDDDD] border-solid top-0 right-0 rounded px-8 pb-8">
      <div className="flex flex-col items-start justify-center ">
        <div className="flex flex-row items-center justify-center gap-4 border-b border-solid border-[#DDDDDD] relative">
          <div className="font-sans font-semibold text-lg text-black">
            User Info
          </div>
          <div
            className="font-sans font-semibold text-lg text-black hover:cursor-pointer absolute right-[-35px]"
            onClick={closeModal}
          >
            x
          </div>
        </div>
        <div className="w-full mt-2">
          <div className="font-sans text-sm font-normal text-black">
            <span className="font-semibold">Hello </span>
            {User.user}
          </div>
          <div className="font-sans text-sm font-normal text-black">
            Campus: {User.campus}
          </div>
          <div className="font-sans text-sm font-normal text-black">
            Role:{User.role}
          </div>
        </div>
      </div>
      {/* Make a logout button */}
      <div className="flex flex-row items-center justify-center gap-4 mt-4">
        <button
          className="font-sans text-sm font-semibold text-white bg-red-500 px-4 py-2 rounded-lg"
          onClick={handleSignOut}
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default UserModal;
