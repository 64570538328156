import React, { useEffect, useState } from "react";
import Admissions from "./Admissions";
import Vouchers from "./Vouchers";
import Receipts from "./Receipts";
import Charges from "./Charges";
import AdmissionReports from "./AdmissionReports";
import FeeReports from "./FeeReports";
import Header from "../header/Header";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const Dashboard = () => {
  const [active, setActive] = useState("admissions");
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const role = user.role
  useEffect(() => {
    if (!user.isSignedIn) {
      navigate("/login");
    }
  }, [user, navigate]);

  const changeTab = (tab) => {
    setActive(tab)
    localStorage.setItem('activeTab', tab)
  }

  useEffect(() => {
    const tab = localStorage.getItem('activeTab')
    if (tab) {
      setActive(tab)
    }
  }
    , [])

  return (
    <div className="flex flex-col items-start justify-center w-full px-4">
      <Header />

      <div className="flex flex-row items-start justify-start w-full">
        {/* Side bar */}
        <div className="flex flex-col items-center justify-center font-sans font-medium text-sm">
          {
            role === 'readwrite' || role === 'write' ?
              <div
                className={`p-4 hover:cursor-pointer border-b border-[#dddddd] border-solid w-full ${active === "admissions" ? "bg-[#DDDDDD]" : ""
                  }`}
                onClick={() => changeTab("admissions")}
              >
                Admissions
              </div>
              : null
          }
          {
            role === 'readwrite' || role === 'write' ?
              <div
                className={`p-4 hover:cursor-pointer border-b border-[#dddddd] border-solid w-full ${active === "vouchers" ? "bg-[#DDDDDD]" : ""
                  }`}
                onClick={() => changeTab("vouchers")}
              >
                Vouchers
              </div>
              : null
          }
          {
            role === 'readwrite' || role === 'write' ?


              <div
                className={`p-4 hover:cursor-pointer border-b border-[#dddddd] border-solid w-full ${active === "receipts" ? "bg-[#DDDDDD]" : ""
                  }`}
                onClick={() => changeTab("receipts")}
              >
                Fee receipts
              </div>
              : null
          }
          {/* <div
            className={`p-4 hover:cursor-pointer border-b border-[#dddddd] border-solid w-full ${
              active === "charges" ? "bg-[#DDDDDD]" : ""
            }`}
            onClick={() => changeTab("charges")}
          >
            Charges / fine
          </div> */}
          <div
            className={`p-4 hover:cursor-pointer border-b border-[#dddddd] border-solid w-full ${active === "admission_reports" ? "bg-[#DDDDDD]" : ""
              }`}
            onClick={() => changeTab("admission_reports")}
          >
            Admission reports
          </div>
          <div
            className={`p-4 hover:cursor-pointer border-b border-[#dddddd] border-solid w-full ${active === "monthly_fee_reports" ? "bg-[#DDDDDD]" : ""
              }`}
            onClick={() => changeTab("monthly_fee_reports")}
          >
            Monthly fee reports
          </div>
        </div>
        {/* Dashboard content */}
        <div className="flex flex-col items-center justify-center border border-solid border-[#DDDDDD w-full">
          {/* Admissions dashboad */}
          <div className="p-4 border-b border-[#dddddd] border-solid w-full font-sans font-medium text-[18px] leading-[19px]">
            {
              {
                admissions: "Student Admissions",
                vouchers: "Monthly Fee Vouchers",
                receipts: "Fee Receipts",
                charges: "Late fee Charges / Fine",
                admission_reports: "Admission Reports",
                monthly_fee_reports: "Monthly Fee Reports",
              }[active]
            }
          </div>
          <div className="flex flex-col items-start justify-start w-full font-sans font-medium text-sm text-[#5A5A5A]">
            {active === "admissions" && <Admissions />}
            {active === "vouchers" && <Vouchers />}
            {active === "receipts" && <Receipts />}
            {active === "charges" && <Charges />}
            {active === "admission_reports" && <AdmissionReports />}
            {active === "monthly_fee_reports" && <FeeReports />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
