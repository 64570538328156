import React from "react";

const Charges = () => {
  return (
    <div>
      {" "}
      <div className="p-4 hover:cursor-pointer hover:text-black">
        Late fee charge
      </div>
      <div className=" p-4 hover:cursor-pointer hover:text-black">
        Student fine
      </div>
      <div className=" p-4 hover:cursor-pointer hover:text-black">
        Edit late fee charge
      </div>
      <div className=" p-4 hover:cursor-pointer hover:text-black">
        Edit student fine
      </div>
    </div>
  );
};

export default Charges;
