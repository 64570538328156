import React from "react";
import { useNavigate } from "react-router-dom";
const FeeReports = () => {
  const navigate = useNavigate();
  return (
    <div>
      {" "}
      <div className="p-4 hover:cursor-pointer hover:text-black" onClick={() => navigate("/print-vouchers")}>
        3-copies fee voucher
      </div>
      <div className=" p-4 hover:cursor-pointer hover:text-black" onClick={() => navigate("/fee-vouchers")}>
        Fee receivables
      </div>
      <div className=" p-4 hover:cursor-pointer hover:text-black" onClick={() => navigate("/fee-received")}>
        Fee received
      </div>
      <div className=" p-4 hover:cursor-pointer hover:text-black" onClick={() => navigate("/fee-record")}>
        Total fee record
      </div>
      <div className=" p-4 hover:cursor-pointer hover:text-black" onClick={() => navigate("/net-receivables")}>
        Net receivables
      </div>
      {/* <div className=" p-4 hover:cursor-pointer hover:text-black">
        Total fee record summary
      </div> */}
    </div>
  );
};

export default FeeReports;
