import { combineReducers } from "redux";
import userReducer from "./userReducer";
import admissionReducer from "./admissionReducer";
import voucherReducer from "./voucherReducer";
const rootReducer = combineReducers({
  user: userReducer,
  admission: admissionReducer,
  voucher: voucherReducer,
});

export default rootReducer;
