import React from "react";
import { useNavigate } from "react-router-dom";

const AdmissionReports = () => {
  const navigate = useNavigate();

  return (
    <div>
      {" "}
      <div className="p-4 hover:cursor-pointer hover:text-black">
        Admission form
      </div>
      <div className=" p-4 hover:cursor-pointer hover:text-black" onClick={() => navigate("/admission-reports")}>
        Admission report
      </div>
      <div className=" p-4 hover:cursor-pointer hover:text-black" onClick={() => navigate("/student-list")}>
        Sudent List
      </div>
    </div>
  );
};

export default AdmissionReports;
