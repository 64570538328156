import React from "react";
import { useNavigate } from "react-router-dom";
const Vouchers = () => {
  const navigate = useNavigate();
  return (
    <div>
      {" "}
      <div
        className="p-4 hover:cursor-pointer hover:text-black"
        onClick={() => navigate("/generate-voucher/campus")}
      >
        Generate monthly fee vouchers for a campus
      </div>
      <div
        className=" p-4 hover:cursor-pointer hover:text-black"
        onClick={() => navigate("/generate-voucher/student")}
      >
        Generate monthly fee vouchers for a student
      </div>
      <div
        className=" p-4 hover:cursor-pointer hover:text-black"
        onClick={() => navigate("/additional-charges")}
      >
        Add additional charges
      </div>
      <div
        className=" p-4 hover:cursor-pointer hover:text-black"
        onClick={() => navigate("/post-vouchers")}
      >
        Post vouchers
      </div>
      {/* <div className=" p-4 hover:cursor-pointer hover:text-black">
        Edit vouchers
      </div> */}
      <div
        className=" p-4 hover:cursor-pointer hover:text-black"
        onClick={() => navigate("/unpost-vouchers")}
      >
        Unpost vouchers
      </div>
    </div>
  );
};

export default Vouchers;
