import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
//////////////////////////////////////////////////////////////////////////
import Header from "../header/Header";
import {
  editStudent,
  startLoading,
  getStudentByRollNo,
  resetAdmissionState,
} from "../../actions/admissionActions";
import { classes, sections } from "../../consts/commons";
import Loader from "../../components/Loader";
const EditAdmissions = () => {
  // Fixed items and states
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //////////////////////////////////////////////////////////////////////////
  const user = useSelector((state) => state.user);
  //////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (!user.isSignedIn) {
      navigate("/login");
    }
  }, [user, navigate]);
  //////////////////////////////////////////////////////////////////////////

  const [showStudentInfo, setShowStudentInfo] = useState(true);
  const admissionLoading = useSelector((state) => state.admission.loading);
  const admissionMessage = useSelector((state) => state.admission.msg);
  const campus = useSelector((state) => state.user.campus);
  const Admission = useSelector((state) => state.admission);
  //////////////////////////////////////////////////////////////////////////

  // Student Information
  const [admissionNo, setAdmissionNo] = useState(null);
  const [admissionDate, setAdmissionDate] = useState("");
  const [registrationNo, setRegistrationNo] = useState(null);
  //////////////////////////////////////////////////////////////////////////

  const [studentName, setStudentName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [placeOfBirth, setPlaceOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [religion, setReligion] = useState("");
  //////////////////////////////////////////////////////////////////////////

  const [address, setAddress] = useState("");
  const [fatherCnic, setFatherCnic] = useState("");
  const [fatherOccupation, setFatherOccupation] = useState("");
  const [fatherHomePhone, setFatherHomePhone] = useState("");
  const [fatherOfficePhone, setFatherOfficePhone] = useState("");
  const [telephone, setTelephone] = useState("");
  const [fatherEmail, setFatherEmail] = useState("");
  //////////////////////////////////////////////////////////////////////////

  const [studentClass, setStudentClass] = useState("");
  const [section, setSection] = useState("");
  const [rollNo, setRollNo] = useState("");
  //////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    let timeoutId;
    const fetchStudentData = () => {
      if (rollNo !== "") {
        dispatch(startLoading());
        dispatch(getStudentByRollNo(rollNo, user.campus));
      }
    };
    // Set a timeout to delay the function call
    timeoutId = setTimeout(fetchStudentData, 500); // Adjust the delay time as needed
    // Clear the previous timeout if the rollNo changes before it fires
    return () => clearTimeout(timeoutId);
  }, [rollNo, dispatch]);

  const studentInformation = () => {
    return (
      <div className="flex flex-row items-start justify-between w-full mt-4 gap-4 ">
        <div className="flex flex-col items-start justify-center w-full ">
          <form className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2 border-b border-solid border-[#DDDDDD] py-2">
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Roll No.
              </label>
              <input
                type="string"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Roll No."
                value={rollNo}
                onChange={(e) => setRollNo(e.target.value)}
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Admission No.
              </label>
              <input
                type="number"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Admission No."
                value={admissionNo}
                onChange={(e) => setAdmissionNo(e.target.value)}
                disabled
              />
            </div>

            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Registration No.
              </label>
              <input
                type="number"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Registration No."
                value={registrationNo}
                onChange={(e) => setRegistrationNo(e.target.value)}
                disabled
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Admission Date
              </label>
              <input
                type="date"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Admission Date"
                value={admissionDate}
                onChange={(e) => setAdmissionDate(e.target.value)}
              />
            </div>
          </form>
          <form className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2 border-b border-solid border-[#DDDDDD] py-2">
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Student Name
              </label>
              <input
                type="text"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Student Name"
                value={studentName}
                onChange={(e) => setStudentName(e.target.value)}
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Father Name
              </label>
              <input
                type="text"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Father Name"
                value={fatherName}
                onChange={(e) => setFatherName(e.target.value)}
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Date of Birth
              </label>
              <input
                type="date"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Date of Birth"
                value={dateOfBirth}
                onChange={(e) => setDateOfBirth(e.target.value)}
              />
            </div>

            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Place of Birth
              </label>
              <input
                type="text"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Place of Birth"
                value={placeOfBirth}
                onChange={(e) => setPlaceOfBirth(e.target.value)}
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Gender
              </label>
              <select
                className="px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <option value="" disabled>
                  Select Gender
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Religion
              </label>
              <input
                type="text"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Religion"
                value={religion}
                onChange={(e) => setReligion(e.target.value)}
              />
            </div>
          </form>
          <form className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2 border-b border-solid border-[#DDDDDD] py-2">
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Address
              </label>
              <textarea
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Father CNIC
              </label>
              <input
                type="number"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Father CNIC"
                value={fatherCnic}
                onChange={(e) => setFatherCnic(e.target.value)}
              />
            </div>

            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Father Occupation
              </label>
              <input
                type="text"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Father Occupation"
                value={fatherOccupation}
                onChange={(e) => setFatherOccupation(e.target.value)}
              />
            </div>

            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Mother Phone Number
              </label>
              <input
                type="number"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Mother Phone number"
                value={telephone}
                onChange={(e) => setTelephone(e.target.value)}
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Father Phone Number
              </label>
              <input
                type="number"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Father Phone number"
                value={fatherHomePhone}
                onChange={(e) => setFatherHomePhone(e.target.value)}
              />
            </div>

            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Office/Shop  Number
              </label>
              <input
                type="number"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder=" Office/Shop  Number"
                value={fatherOfficePhone}
                onChange={(e) => setFatherOfficePhone(e.target.value)}
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Father Email
              </label>
              <input
                type="email"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Father Email"
                value={fatherEmail}
                onChange={(e) => setFatherEmail(e.target.value)}
              />
            </div>
          </form>
        </div>
        <div className="flex flex-col items-start justify-center w-full gap-2">
          <form className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2 border-b border-solid border-[#DDDDDD] py-2">
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">Class</label>
              <select
                className="px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                value={studentClass}
                onChange={(e) => setStudentClass(e.target.value)}
              >
                <option value="" disabled>
                  Select Class
                </option>
                {classes.map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </div>

            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Section
              </label>
              <select
                className="px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                value={section}
                onChange={(e) => setSection(e.target.value)}
              >
                <option value="" disabled>
                  Select Section
                </option>
                {sections.map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </div>


          </form>
        </div>
      </div>
    );
  };
  // End of Student Information


  //////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (admissionMessage === "Admission edited successfully") {
      alert(admissionMessage);
      dispatch(resetAdmissionState());
      navigate(-1);
    } else if (admissionMessage === "Editing admission failed") {
      alert(admissionMessage);
      dispatch(resetAdmissionState());
    }
    else if (admissionMessage === "Student info fetched successfully") {
      setStudentName(Admission.studentPersonalInfo.studentName)
      setFatherName(Admission.studentPersonalInfo.fatherName)
      setDateOfBirth(Admission.studentPersonalInfo.dateOfBirth)
      setPlaceOfBirth(Admission.studentPersonalInfo.placeOfBirth)
      setGender(Admission.studentPersonalInfo.gender)
      setReligion(Admission.studentPersonalInfo.religion)
      setAddress(Admission.studentPersonalInfo.address)
      setFatherCnic(Admission.studentPersonalInfo.fatherCnic)
      setFatherOccupation(Admission.studentPersonalInfo.fatherOccupation)
      setFatherHomePhone(Admission.studentPersonalInfo.fatherHomePhone)
      setFatherOfficePhone(Admission.studentPersonalInfo.fatherOfficePhone)
      setTelephone(Admission.studentPersonalInfo.telephone)
      setFatherEmail(Admission.studentPersonalInfo.fatherEmail)
      setStudentClass(Admission.studentPersonalInfo.studentClass)
      setSection(Admission.studentPersonalInfo.section)
      setAdmissionNo(Admission.studentPersonalInfo.admissionNo)
      setAdmissionDate(Admission.studentPersonalInfo.admissionDate)
      setRegistrationNo(Admission.studentPersonalInfo.registrationNo)
      dispatch(resetAdmissionState());
    }
  }, [admissionMessage]);

  //////////////////////////////////////////////////////////////////////////

  const submitForm = async () => {
    // Check if all fields are filled
    if (studentName === "" || fatherName === "" || dateOfBirth === "" || placeOfBirth === "" || gender === "" || religion === "" || address === "" || fatherCnic === "" || fatherOccupation === "" || fatherHomePhone === "" || fatherOfficePhone === "" || telephone === "" || fatherEmail === "" || studentClass === "" || section === "" || rollNo === "") { alert("Please fill all the fields"); return }

    const data = {
      studentData: {
        admissionNo,
        admissionDate,
        registrationNo,
        studentName,
        fatherName,
        dateOfBirth,
        placeOfBirth,
        gender,
        religion,
        address,
        fatherCnic,
        fatherOccupation,
        fatherHomePhone,
        fatherOfficePhone,
        telephone,
        fatherEmail,
        studentClass,
        section,
        rollNo,
        campus,
      },
    };
    dispatch(startLoading());
    dispatch(editStudent(data));
  };
  //////////////////////////////////////////////////////////////////////////

  return (
    <div className="flex flex-col items-start justify-center px-4">
      {admissionLoading && <Loader />}
      <Header />
      <div className="flex flex-col items-start justify-center w-full border-b border-solid border-[#DDDDDD] py-2 px-1">
        <div className="flex flex-row items-center justify-start w-full gap-4">
          <h1 className="text-3xl font-bold text-center text-gray-800">
            Edit Admissions
          </h1>
          <h2 className="text-xl font-semibold text-center text-gray-500">
            {showStudentInfo ? "Student Information" : "Financial Information"}
          </h2>
        </div>
        <h2 className="text-xl font-semibold text-center text-gray-500">
          {campus} Campus
        </h2>
      </div>
      {studentInformation()}
      <div className="flex flex-row items-center justify-center w-full gap-4 mt-4">

        <button
          className="px-4 py-2 text-base font-bold text-white bg-green-500 rounded-md"
          onClick={submitForm}
        >
          Submit
        </button>

        <button
          className="px-4 py-2 text-base font-bold text-white bg-blue-500 rounded-md"
          onClick={() => navigate(-1)}
        >
          Back
        </button>


        <button
          className="px-4 py-2 text-base font-bold text-white bg-red-500 rounded-md"
          onClick={() => navigate(-1)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default EditAdmissions;
