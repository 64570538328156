import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../header/Header";
import Loader from "../../components/Loader";
import {
  getIndividualVoucher,
  startLoading,
  resetVoucher,
  payIndividualVoucher,
} from "../../actions/voucherActions";

const IndividualReceipts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const User = useSelector((state) => state.user);
  //////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (!User.isSignedIn) {
      navigate("/login");
    }
  }, [User, navigate]);
  //////////////////////////////////////////////////////////////////////////
  const Voucher = useSelector((state) => state.voucher);
  const loading = Voucher.loading;
  const [rollNo, setRollNo] = useState("");
  const [name, setName] = useState("");
  const [studentClass, setStudentClass] = useState("");
  const [section, setSection] = useState("");
  const [allowSubmit, setAllowSubmit] = useState(false);

  const [netReceivable, setNetReceivable] = useState("");
  const [receivedBy, setReceivedBy] = useState("");
  const [receivedAmount, setReceivedAmount] = useState("");
  const [receivedDate, setReceivedDate] = useState("");

  useEffect(() => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const today = `${year}-${month}-${day}`;
    setReceivedDate(today);
    setReceivedBy(User.user);
  }, []);

  useEffect(() => {
    let timeoutId;
    const fetchStudentData = () => {
      if (rollNo !== "") {
        dispatch(startLoading());
        dispatch(getIndividualVoucher(User.campus, rollNo));
      }
    };
    // Set a timeout to delay the function call
    timeoutId = setTimeout(fetchStudentData, 500); // Adjust the delay time as needed
    // Clear the previous timeout if the rollNo changes before it fires
    return () => clearTimeout(timeoutId);
  }, [rollNo]);

  useEffect(() => {
    if (Voucher && Voucher.vouchers) {
      setName(Voucher.vouchers.studentName);
      setStudentClass(Voucher.vouchers.studentClass);
      setSection(Voucher.vouchers.section);
      setNetReceivable(
        Voucher.vouchers.arrears + Voucher.vouchers.netMonthlyFee + Voucher.vouchers.examinationFee + Voucher.vouchers.registrationFee +
        Voucher.vouchers.admissionFee 
      );
      setAllowSubmit(true);
    } else {
      setName("");
      setStudentClass("");
      setSection("");
      setNetReceivable("");
      setAllowSubmit(false);
    }
  }, [Voucher]);

  useEffect(() => {
    if (Voucher.msg === "Voucher Paid") {
      dispatch(resetVoucher());
      alert("Voucher Paid");
    } else if (Voucher.msg === "No vouchers found") {
      alert("Voucher Not Found");
      dispatch(resetVoucher());
    } else if (Voucher.msg === "Voucher not posted") {
      alert("Voucher Not Posted");
      dispatch(resetVoucher());
    }
    else if (Voucher.msg === "Voucher already paid") {
      alert("Voucher already paid");
      dispatch(resetVoucher());
    }
  }, [Voucher.msg]);

  const handleRollNumberInput = (e) => {
    const newRollNo = e.target.value;
    setRollNo(newRollNo);
  };
  const handleReceive = () => {
      const data = {
        receivedBy: receivedBy,
        receivedDate: receivedDate,
        receivedAmount: receivedAmount,
      };
      dispatch(startLoading());
      dispatch(payIndividualVoucher(User.campus, rollNo, data));
  };
  return (
    <div className="flex flex-col items-start justify-center px-4">
      <Header />
      <div className="flex flex-row items-center justify-start w-full border-b border-solid border-[#DDDDDD] py-2">
        <h1 className="text-3xl font-bold text-center text-gray-800">
          Individual Fee Receipts
        </h1>
        <h2 className="ml-4 text-xl font-semibold text-center text-gray-500">
          {User.campus} Campus
        </h2>
      </div>
      <div className="flex flex-row items-start justify-between w-full mt-4 gap-4 ">
        <div className="flex flex-col items-start justify-center w-full ">
          <form className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2 border-b border-solid border-[#DDDDDD] py-2">
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Roll No.
              </label>
              <input
                type="number"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Roll No."
                value={rollNo}
                onChange={handleRollNumberInput}
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">Name</label>
              <input
                type="text"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Name"
                value={name}
                disabled
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">Class</label>
              <input
                type="text"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Class"
                value={studentClass}
                disabled
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Section
              </label>
              <input
                type="text"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Section"
                value={section}
                disabled
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Net Receivable
              </label>
              <input
                type="number"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Net Receivable"
                value={netReceivable}
                disabled
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Received Amount
              </label>
              <input
                type="text"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Received Amount"
                value={receivedAmount}
                onChange={(e) => setReceivedAmount(e.target.value)}
              />
            </div>
          </form>
          <div className="flex flex-row items-center justify-center w-full gap-4 mt-4">
            <button
              className={
                allowSubmit
                  ? "px-4 py-2 text-base font-bold text-white bg-green-900 rounded-md"
                  : "px-4 py-2 text-base font-bold text-white bg-gray-500 rounded-md hover:cursor-not-allowed"
              }
              disabled={!allowSubmit}
              onClick={handleReceive}
            >
              Receive
            </button>
            <button
              className="px-4 py-2 text-base font-bold text-white bg-red-500 rounded-md"
              onClick={() => {
                navigate(-1);
                dispatch(resetVoucher());
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default IndividualReceipts;
