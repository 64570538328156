import React from "react";
import { useNavigate } from "react-router-dom";

const Admissions = () => {
  const navigate = useNavigate();
  const handleClick = (where) => {
    if (where === "new-admissions") {
      navigate("/new-admissions");
    } else if (where === "promotions") {
      navigate("/promotions");
    } else if (where === "graduation") {
      navigate("/graduation");
    } else if (where === "student-removal") {
      navigate("/student-removal");
    } else if (where === "student-fee-change") {
      navigate("/student-fee-change");
    }
    else if (where === "edit-admissions") {
      navigate("/edit-admissions");
    }
    else if (where === "edit-vouchers") {
      navigate("/edit-vouchers");
    }
  };
  return (
    <div>
      {" "}
      <div
        className="p-4 hover:cursor-pointer hover:text-black"
        onClick={() => handleClick("new-admissions")}
      >
        Add new Admission
      </div>
      <div
        className="p-4 hover:cursor-pointer hover:text-black"
        onClick={() => handleClick("edit-admissions")}
      >
        Edit student information
      </div>
      <div
        className="p-4 hover:cursor-pointer hover:text-black"
        onClick={() => handleClick("edit-vouchers")}
      >
        Edit student voucher
      </div>
      <div
        className=" p-4 hover:cursor-pointer hover:text-black"
        onClick={() => handleClick("promotions")}
      >
        Class Promotions
      </div>
      <div
        className=" p-4 hover:cursor-pointer hover:text-black"
        onClick={() => handleClick("graduation")}
      >
        Class removal / Graduation
      </div>
      <div
        className=" p-4 hover:cursor-pointer hover:text-black"
        onClick={() => handleClick("student-removal")}
      >
        Student removal / withrawal, Graduation
      </div>
      {/* <div
        className=" p-4 hover:cursor-pointer hover:text-black"
        onClick={() => handleClick("student-fee-change")}
      >
        Change tution fee for a student
      </div> */}
      {/* <div className=" p-4 hover:cursor-pointer hover:text-black">
        Change tution fee for a class
      </div> */}
    </div>
  );
};

export default Admissions;
