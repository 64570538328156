import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  promoteClass,
  startLoading,
  resetAdmissionState,
} from "../../actions/admissionActions";
import Header from "../header/Header";
import { classes } from "../../consts/commons";
import Loader from "../../components/Loader";
const Promotions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const User = useSelector((state) => state.user);
  //////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (!User.isSignedIn) {
      navigate("/login");
    }
  }, [User, navigate]);
  //////////////////////////////////////////////////////////////////////////
  const Admission = useSelector((state) => state.admission);
  const loading = useSelector((state) => state.admission.loading);
  const dispatch = useDispatch();

  const [classSelected, setClassSelected] = useState(null);
  const [isGraduationPage, setIsGraduationPage] = useState(false);
  const [graduationData, setGraduationData] = useState(null);

  const [buttonActive, setButtonActive] = useState(false);
  useEffect(() => {
    if (location.pathname.includes("graduation")) {
      setIsGraduationPage(true);
    }
  }, [location.pathname]);

  const handlePromote = () => {
    if (!isGraduationPage) {
      if (classSelected === "10") {
        return alert(
          "You can't promote class 10 to next class. Please use graduation page instead."
        );
      } else {
        // The bool value is for checking if we graduate the class or promote it
        const data = {
          classToPromote: classSelected,
          graduationDate: null,
          isGraduation: false,
          campus: User.campus,
        };
        dispatch(startLoading());
        dispatch(promoteClass(data, false));
      }
    } else {
      // The bool value is for checking if we graduate the class or promote it
      const data = {
        classToPromote: classSelected,
        graduationDate: graduationData,
        isGraduation: true,
        campus: User.campus,
      };
      dispatch(startLoading());
      dispatch(promoteClass(data));
    }
  };

  useEffect(() => {
    if (
      Admission.msg === "Class promoted successfully" ||
      Admission.msg === "Class graduated successfully"
    ) {
      alert(Admission.msg);
      dispatch(resetAdmissionState());
      navigate(-1);
    }
  }, [Admission.msg]);

  useEffect(() => {
    if (Admission.failed) {
      alert(`Process failed because ${Admission.msg}`);
      dispatch(resetAdmissionState());
    }
  }, [Admission.failed]);

  useEffect(() => {
    if (isGraduationPage) {
      if (classSelected && graduationData) {
        setButtonActive(true);
      } else {
        setButtonActive(false);
      }
    } else {
      if (classSelected) {
        setButtonActive(true);
      } else {
        setButtonActive(false);
      }
    }
  }, [classSelected, graduationData]);
  return (
    <div className="flex flex-col items-start justify-center px-4">
      {loading && <Loader />}
      <Header />
      <div className="flex flex-row items-center justify-start w-full border-b border-solid border-[#DDDDDD] py-2">
        <h1 className="text-3xl font-bold text-center text-gray-800">
          {isGraduationPage ? "Graduation" : "Promotions"}
        </h1>
        <h2 className="ml-4 text-xl font-semibold text-center text-gray-500">
          {User.campus} Campus
        </h2>
      </div>
      <div className="flex flex-row items-center justify-center w-full mt-4">
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-base font-bold text-gray-800">Select Class</h1>
          <select
            className="w-full px-4 py-2 mt-2 border border-solid border-[#DDDDDD] rounded outline-none"
            name="class"
            id="class"
            onChange={(e) => setClassSelected(e.target.value)}
          >
            <option value="" disabled selected>
              Select Class
            </option>
            {classes.map((classs) => (
              <option key={classs} value={classs}>
                {classs}
              </option>
            ))}
          </select>
        </div>
        {isGraduationPage && (
          <div className="flex flex-col items-center justify-center ml-4">
            <h1 className="text-base font-bold text-gray-800">
              Select Graduation Date
            </h1>
            <input
              className="w-full px-4 py-2 mt-2 border border-solid border-[#DDDDDD] rounded outline-none"
              type="date"
              name="graduationDate"
              id="graduationDate"
              onChange={(e) => setGraduationData(e.target.value)}
            />
          </div>
        )}
      </div>
      <div className="flex flex-row items-center justify-center w-full gap-4 mt-4">
        <button
          className={
            buttonActive
              ? "px-4 py-2 text-base font-bold text-white bg-green-900 rounded-md"
              : "px-4 py-2 text-base font-bold text-white bg-gray-500 rounded-md hover:cursor-not-allowed"
          }
          onClick={handlePromote}
          disabled={!buttonActive}
        >
          {isGraduationPage ? "Graduate" : "Promote"}
        </button>

        <button
          className="px-4 py-2 text-base font-bold text-white bg-red-500 rounded-md"
          onClick={() => navigate(-1)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default Promotions;
