import { address } from "./server";
import axios from "axios";

export const getLastRollNo = () => {
  const url = `${address}/admissions/getLastRollNumber`;
  return async (dispatch) => {
    await axios.get(url).then((res) => {
      if (res.status === 200) {
        dispatch({
          type: "GET_LAST_ROLL_NO",
          payload: res.data,
        });
      }
    });
  };
};

export const postAdmission = (admissionData) => {
  const url = `${address}/admissions/newAdmission`;
  return async (dispatch) => {
    await axios
      .post(url, admissionData)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "POST_ADMISSION",
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "POST_ADMISSION_FAILED",
          payload: err,
        });
      });
  };
};

export const promoteClass = (data) => {
  const url = `${address}/admissions/promoteClass`;
  return async (dispatch) => {
    await axios
      .post(url, data)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "PROMOTE_CLASS",
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "PROMOTE_CLASS_FAILED",
          payload: err,
        });
      });
  };
};

export const getStudentByRollNo = (rollNo, campus) => {
  const url = `${address}/admissions/getStudentInfo/${campus}/${rollNo}`;
  return async (dispatch) => {
    await axios
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "GET_STUDENT_BY_ROLL_NO",
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "GET_STUDENT_BY_ROLL_NO_FAILED",
          payload: err,
        });
      });
  };
};

export const removeStudent = (rollNo, removalDate) => {
  const body = {
    removalDate: removalDate,
  };
  const url = `${address}/admissions/removeStudent/${rollNo}`;
  return async (dispatch) => {
    await axios
      .post(url, body)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "REMOVE_STUDENT",
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "REMOVE_STUDENT_FAILED",
          payload: err,
        });
      });
  };
};

export const changeStudentFee = (rollNo, newFee) => {
  const body = {
    newFee: newFee,
  };
  const url = `${address}/admissions/changeStudentFee/${rollNo}`;
  return async (dispatch) => {
    await axios
      .post(url, body)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "CHANGE_STUDENT_FEE",
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "CHANGE_STUDENT_FEE_FAILED",
          payload: err,
        });
      });
  };
};

export const startLoading = () => {
  return {
    type: "START_ADMISSION_LOADING",
  };
};

export const resetAdmissionState = () => {
  return {
    type: "RESET_ADMISSION_STATE",
  };
};

export const markArrearsPaid = (rollNo, body) => {
  const url = `${address}/admissions/markArrearsPaid/${rollNo}`;
  return async (dispatch) => {
    await axios
      .post(url, body)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "MARK_ARREARS_PAID",
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "MARK_ARREARS_PAID_FAILED",
          payload: err,
        });
      });
  };
};

export const getStudentList = (className, section, campus) => {
  const url = `${address}/admissions/getStudentList/${className}/${section}/${campus}`;
  return async (dispatch) => {
    await axios
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "GET_STUDENT_LIST",
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "GET_STUDENT_LIST_FAILED",
          payload: err,
        });
      });
  };
};

export const getAdmissionReports = (body, type, campus) => {
  const url = `${address}/admissions/getAdmissionReport/${type}/${campus}`;
  return async (dispatch) => {
    await axios
      .post(url, body)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "GET_ADMISSION_REPORTS",
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "GET_ADMISSION_REPORTS_FAILED",
          payload: err,
        });
      });
  };

}

export const editStudent = (body) => {
  const url = `${address}/admissions/editAdmission`;
  return async (dispatch) => {
    await axios
      .post(url, body)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "EDIT_ADMISSION",
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "EDIT_ADMISSION_FAILED",
          payload: err,
        });
      });
  };
}

export const editVoucher = (body, campus) => {
  const url = `${address}/admissions/editAdmissionFee`;
  return async (dispatch) => {
    await axios
      .post(url, body)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "EDIT_VOUCHER",
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "EDIT_VOUCHER_FAILED",
          payload: err,
        });
      });
  };
}