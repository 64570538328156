import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Header from '../header/Header';
import { classes, sections } from '../../consts/commons';
import { getAdmissionReports, startLoading, resetAdmissionState } from '../../actions/admissionActions';
import Loader from '../../components/Loader';
import Graph from './Graph';
import { useNavigate } from 'react-router-dom';
const AdmissionReports = () => {
    const navigate = useNavigate();
    const User = useSelector((state) => state.user);
    //////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (!User.isSignedIn) {
            navigate("/login");
        }
    }, [User, navigate]);
    //////////////////////////////////////////////////////////////////////////
    const Admission = useSelector((state) => state.admission);
    const admissionLoading = Admission.loading;
    const dispatch = useDispatch();
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [selectedClass, setSelectedClass] = useState('all');
    const [selectedSection, setSelectedSection] = useState('all');
    const [count, setCount] = useState(0);
    const [dataType, setDataType] = useState('')
    const [haveData, setHaveData] = useState(false);
    const [classwiseDataAdmitted, setClasswiseDataAdmitted] = useState([]);
    const [classWiseDataRemoved, setclasswiseDataRemoved] = useState([]);
    const [graphView, setGraphView] = useState(false);
    const getReports = (type) => {
        setCount(0)
        setHaveData(false)

        dispatch(resetAdmissionState());
        dispatch(startLoading());
        const body = {
            fromDate,
            toDate,
            selectedClass,
            selectedSection,
        };
        setDataType(type);
        dispatch(getAdmissionReports(body, type, User.campus));
    }

    useEffect(() => {
        if (Admission.msg === "Getting admission reports failed") {
            alert("Getting admission reports failed");
            dispatch(resetAdmissionState());

        }
        else if (Admission.msg === "No student found") {
            alert("No student found");
            dispatch(resetAdmissionState());
        }
        else {
            if (Admission.admissionReports && Admission.admissionReports.length > 0) {
                setCount(Admission.admissionReports.length)
                setHaveData(true)
                // go through admissionReports and get the count of each class and store it in classwiseDataAdmitted along with the section if isRemoved = false
                const admitted = {};
                const removed = {};
                Admission.admissionReports.forEach((item) => {
                    if (item.isRemoved === false) {
                        if (admitted[item.admissionClass]) {
                            admitted[item.admissionClass] += 1;
                        } else {
                            admitted[item.admissionClass] = 1;
                        }
                    } else {
                        if (removed[item.admissionClass]) {
                            removed[item.admissionClass] += 1;
                        } else {
                            removed[item.admissionClass] = 1;
                        }
                    }
                });
                setClasswiseDataAdmitted(admitted);
                setclasswiseDataRemoved(removed);


            }
        }
    }, [Admission.msg]);

    useEffect(() => {
        setHaveData(false)
        setCount(0)
        setClasswiseDataAdmitted([])
        setclasswiseDataRemoved([])
    }
        , [selectedClass, selectedSection])

    return (
        <div className="flex flex-col items-start justify-center px-4 space-y-4">
            {(admissionLoading) && <Loader />}
            <Header />
            <div className="flex flex-row items-center justify-start w-full border-b border-solid border-[#DDDDDD] py-2">
                <h1 className="text-3xl font-bold text-center text-gray-800">
                    Admission Reports
                </h1>
                <h2 className="ml-4 text-xl font-semibold text-center text-gray-500">
                    {User.campus} Campus
                </h2>
            </div>
            <div className="flex flex-col md:flex-row items-center justify-between w-full mt-4 space-y-4 md:space-y-0 md:space-x-4 ">
                <div className="flex flex-col items-start w-full space-y-4 md:w-1/4">
                    <label className="text-lg font-semibold text-gray-600">
                        Class
                    </label>
                    <select
                        value={selectedClass}
                        onChange={(e) => setSelectedClass(e.target.value)}
                        className="w-full p-2 border border-solid border-[#DDDDDD] rounded-md"
                    >
                        <option value="all">All</option>
                        {classes.map((item, index) => (
                            <option key={index} value={item}>
                                {item}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="flex flex-col items-start w-full space-y-4 md:w-1/4">
                    <label className="text-lg font-semibold text-gray-600">
                        Section
                    </label>
                    <select
                        value={selectedSection}
                        onChange={(e) => setSelectedSection(e.target.value)}
                        className="w-full p-2 border border-solid border-[#DDDDDD] rounded-md"
                    >
                        <option value="all">All</option>
                        {sections.map((item, index) => (
                            <option key={index} value={item}>
                                {item}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="flex flex-col items-start w-full space-y-4 md:w-1/4">
                    <label className="text-lg font-semibold text-gray-600">
                        From Date
                    </label>
                    <input
                        type="date"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                        className="w-full p-2 border border-solid border-[#DDDDDD] rounded-md"
                    />
                </div>
                <div className="flex flex-col items-start w-full space-y-4 md:w-1/4">
                    <label className="text-lg font-semibold text-gray-600">
                        To Date
                    </label>
                    <input
                        type="date"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                        className="w-full p-2 border border-solid border-[#DDDDDD] rounded-md"
                    />
                </div>
            </div>
            <div className="flex flex-row items-center justify-start mt-4 space-x-4 border-b border-solid border-[#DDDDDD] pb-4">
                <button
                    className="p-2 px-4 text-lg font-semibold text-white  bg-cyan-700 rounded-md"
                    onClick={() => getReports('admissions')}
                >
                    Generate Report for admission
                </button>
                <button
                    className="p-2 px-4 text-lg font-semibold text-white bg-[#FF8C00] rounded-md"
                    onClick={() => getReports('removals')}
                >
                    Generate Report for students left
                </button>
                <button
                    className="p-2 px-4 text-lg font-semibold text-white bg-[#2ECC71] rounded-md"
                    onClick={() => getReports('all')}
                >
                    Generate Report for both
                </button>
                <button
                    className="p-2 px-4 text-lg font-semibold text-white	 bg-[#FF5757] rounded-md"
                    onClick={() => navigate(-1)}
                >
                    Cancel
                </button>
            </div>
            {haveData ?
                <div className='flex flex-col items-start justify-center w-full mt-4 space-y-4 '>
                    <div className='flex flex-row items-center justify-between w-full  py-2 border-b border-solid border-[#DDDDDD]'>
                        <h1 className='text-xl font-bold text-gray-800'>Total

                            {
                                dataType === 'admissions' ? ' Admissions'
                                    : dataType === 'removals' ? ' Students Left'
                                        : ' Admissions and Students Left'
                            }
                            : {count}
                        </h1>
                    </div>
                    <div className='flex flex-col items-center justify-between w-full  py-2 pb-10'>
                        <div className='flex flex-row items-center justify-between w-full p-2'>
                            <h1 className='text-xl font-bold text-gray-800'>Classwise Data</h1>
                            {
                                graphView ? <h1 className='text-base font-bold text-gray-400 hover:cursor-pointer' onClick={() => setGraphView(false)}>Show table</h1>
                                    : <h1 className='text-base font-bold text-gray-400 hover:cursor-pointer' onClick={() => setGraphView(true)}>Show graph</h1>
                            }
                        </div>
                        {
                            graphView ? <div className='flex flex-row items-center justify-between w-full p-2'>
                                <Graph data={{ dataType, classwiseDataAdmitted, classWiseDataRemoved }} />

                            </div>

                                :
                                <table className="min-w-full border border-solid border-[#CCCCCC]">
                                    <thead className="text-xs font-semibold uppercase bg-gray-200 text-gray-600">
                                        <tr>
                                            <th className="px-2 py-3 text-center">Class</th>
                                            {/* heading label only if datatype is admissions or all */}
                                            {dataType === 'admissions' || dataType === 'all' ?
                                                <th className="px-2 py-3 text-center">Admitted</th>
                                                : null}
                                            {/* heading label only if datatype is removals or all */}
                                            {dataType === 'removals' || dataType === 'all' ?
                                                <th className="px-2 py-3 text-center">Left</th>
                                                : null}
                                        </tr>
                                    </thead>
                                    <tbody className="text-xs bg-white text-gray-600">
                                        {/* if selected class is not all then render all classes for table else only that class */}
                                        {selectedClass === 'all' ? (
                                            classes.map((item, index) => (
                                                <tr key={index} className="border border-solid border-[#CCCCCC] text-sm">
                                                    <td className="px-2 py-3 text-center">{item}</td>
                                                    {/* Render cell based on dataType */}
                                                    {dataType === 'admissions' || dataType === 'all' ? (
                                                        <td className="px-2 py-3 text-center">{classwiseDataAdmitted[item] || 0}</td>
                                                    ) : null}
                                                    {dataType === 'removals' || dataType === 'all' ? (
                                                        <td className="px-2 py-3 text-center">{classWiseDataRemoved[item] || 0}</td>
                                                    ) : null}
                                                </tr>
                                            ))
                                        ) : (
                                            <tr className="border border-solid border-[#CCCCCC] text-sm">
                                                <td className="px-2 py-3 text-center">{selectedClass}</td>
                                                {/* Render cell based on dataType */}
                                                {dataType === 'admissions' || dataType === 'all' ? (
                                                    <td className="px-2 py-3 text-center">{classwiseDataAdmitted[selectedClass] || 0}</td>
                                                ) : null}
                                                {dataType === 'removals' || dataType === 'all' ? (
                                                    <td className="px-2 py-3 text-center">{classWiseDataRemoved[selectedClass] || 0}</td>
                                                ) : null}
                                            </tr>
                                        )}


                                    </tbody>
                                </table>
                        }
                    </div>


                </div>
                : null}
        </div >
    );
};

export default AdmissionReports;
