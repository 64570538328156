import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
//////////////////////////////////////////////////////////////////////////
import Header from "../header/Header";
import {
    startLoading,
    getStudentByRollNo,
    resetAdmissionState,
    editVoucher,

} from "../../actions/admissionActions";
import Loader from "../../components/Loader";
const EditVouchers = () => {
    // Fixed items and states
    const dispatch = useDispatch();
    const navigate = useNavigate();
    //////////////////////////////////////////////////////////////////////////
    const user = useSelector((state) => state.user);
    //////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (!user.isSignedIn) {
            navigate("/login");
        }
    }, [user, navigate]);
    //////////////////////////////////////////////////////////////////////////

    const admissionLoading = useSelector((state) => state.admission.loading);
    const admissionMessage = useSelector((state) => state.admission.msg);
    const Admission = useSelector((state) => state.admission);
    const campus = useSelector((state) => state.user.campus);
    const feeInfo = useSelector((state) => state.admission.studentMonthlyFeeInfo);
    //////////////////////////////////////////////////////////////////////////


    //////////////////////////////////////////////////////////////////////////
    const [studentClass, setStudentClass] = useState("");
    const [section, setSection] = useState("");
    const [name, setName] = useState("");
    const [fatherName, setFatherName] = useState("");

    const [rollNo, setRollNo] = useState("");
    //////////////////////////////////////////////////////////////////////////


    // End of Student Information

    // Financial Information
    const [registrationFee, setRegistrationFee] = useState(null);
    const [admissionFee, setAdmissionFee] = useState(null);
    const [securityFee, setSecurityFee] = useState(null);
    const [monthlyTuitionFee, setMonthlyTuitionFee] = useState(null);
    const [discount, setDiscount] = useState(null);
    const [netMonthlyFee, setNetMonthlyFee] = useState(null);
    const [examinationFee, setExaminationFee] = useState(null);
    const [totalReceivable, setTotalReceivable] = useState(null);
    //////////////////////////////////////////////////////////////////////////


    const calculateNetMonthlyFee = () => {
        const netMonthlyFee = monthlyTuitionFee - discount;
        setNetMonthlyFee(netMonthlyFee);
    };
    //////////////////////////////////////////////////////////////////////////


    //////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        let timeoutId;

        const fetchStudentData = () => {
            if (rollNo !== "") {

                dispatch(startLoading());
                dispatch(getStudentByRollNo(rollNo, user.campus));
            }
        };
        // Set a timeout to delay the function call
        timeoutId = setTimeout(fetchStudentData, 500); // Adjust the delay time as needed
        // Clear the previous timeout if the rollNo changes before it fires
        return () => clearTimeout(timeoutId);
    }, [rollNo, dispatch]);


    useEffect(() => {
        if (admissionMessage && !admissionMessage.includes("Student info fetched successfully")) {
            alert(admissionMessage)
            setRollNo('');
            setMonthlyTuitionFee('');
            setRegistrationFee('');
            setAdmissionFee('');
            setExaminationFee('');
            setDiscount('');
        }
        else {
            if (feeInfo) {
                setMonthlyTuitionFee(feeInfo.monthlyFee)
                setRegistrationFee(feeInfo.registrationFee)
                setAdmissionFee(feeInfo.admissionFee)
                setExaminationFee(feeInfo.examinationFee)
                setDiscount(feeInfo.discount)
                setName(Admission.studentPersonalInfo.studentName);
                setFatherName(Admission.studentPersonalInfo.fatherName);
                setStudentClass(Admission.studentPersonalInfo.studentClass);
                setSection(Admission.studentPersonalInfo.section);
            }
        }
    }, [admissionMessage]);
    const financialInformation = () => {
        return (
            <div className="flex flex-row items-start justify-between w-full mt-4 gap-4 ">
                <div className="flex flex-col items-start justify-center w-full ">
                    <form className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2 border-b border-solid border-[#DDDDDD] py-2">
                        <div className="flex flex-col items-start justify-center w-full gap-2">
                            <label className="text-base font-bold text-gray-800">
                                Roll No.
                            </label>
                            <input
                                type="string"
                                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                                placeholder="Roll No."
                                value={rollNo}
                                onChange={(e) => setRollNo(e.target.value)}
                            />
                        </div>
                        <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">Name</label>
              <input
                type="text"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Name"
                value={name}
                disabled
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Father Name
              </label>
              <input
                type="text"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Father Name"
                value={fatherName}
                disabled
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">Class</label>
              <input
                type="text"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Class"
                value={studentClass}
                disabled
              />
            </div>
            <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Section
              </label>
              <input
                type="text"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Section"
                value={section}
                disabled
              />
            </div>
                        <div className="flex flex-col items-start justify-center w-full gap-2">
                            <label className="text-base font-bold text-gray-800">
                                Registration Fee
                            </label>
                            <input
                                type="number"
                                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                                placeholder="Registration Fee"
                                value={parseFloat(registrationFee)}
                                onChange={(e) => setRegistrationFee(parseFloat(e.target.value))}
                            />
                        </div>
                        <div className="flex flex-col items-start justify-center w-full gap-2">
                            <label className="text-base font-bold text-gray-800">
                                Admission Fee
                            </label>
                            <input
                                type="number"
                                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                                placeholder="Admission Fee"
                                value={admissionFee}
                                onChange={(e) => setAdmissionFee(parseFloat(e.target.value))}
                            />
                        </div>
                        {/* <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Security Fee
              </label>
              <input
                type="number"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                placeholder="Security Fee"
                value={securityFee}
                onChange={(e) => setSecurityFee(parseFloat(e.target.value))}
              />
            </div> */}
                        <div className="flex flex-col items-start justify-center w-full gap-2">
                            <label className="text-base font-bold text-red-500">
                                Monthly Tuition Fee
                            </label>
                            <input
                                type="number"
                                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md text-red-500"
                                placeholder="Monthly Tuition Fee"
                                value={monthlyTuitionFee}
                                onChange={(e) =>
                                    setMonthlyTuitionFee(parseFloat(e.target.value))
                                }
                            />
                        </div>
                        <div className="flex flex-col items-start justify-center w-full gap-2">
                            <label className="text-base font-bold text-gray-800">
                                Discount
                            </label>
                            <input
                                type="number"
                                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md"
                                placeholder="Discount"
                                value={discount}
                                onChange={(e) => setDiscount(parseFloat(e.target.value))}
                            />
                        </div>
                        <div className="flex flex-col items-start justify-center w-full gap-2">
                            <label className="text-base font-bold text-green-600">
                                Net Monthly Fee
                            </label>
                            <input
                                type="number"
                                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md  text-red-500"
                                placeholder="Net Monthly Fee"
                                value={netMonthlyFee}
                                disabled
                            />
                        </div>
                        {/* <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Transport Fee
              </label>
              <input
                type="number"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md text-gray-800"
                placeholder="Transport Fee"
                value={transportFee}
                onChange={(e) => setTransportFee(parseFloat(e.target.value))}
              />
            </div> */}
                        {/* <div className="flex flex-col items-start justify-center w-full gap-2">
              <label className="text-base font-bold text-gray-800">
                Arrears
              </label>
              <input
                type="number"
                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md text-gray-800"
                placeholder="Arrears"
                value={arrears}
                onChange={(e) => setArrears(parseFloat(e.target.value))}
              />
            </div> */}
                        <div className="flex flex-col items-start justify-center w-full gap-2">
                            <label className="text-base font-bold text-gray-800">
                                Examination Fee
                            </label>
                            <input
                                type="number"
                                className=" px-4 py-2 border border-solid border-[#DDDDDD] rounded-md text-gray-800"
                                placeholder="Examination Fee"
                                value={examinationFee}
                                onChange={(e) => setExaminationFee(parseFloat(e.target.value))}
                            />
                        </div>

                    </form>
                </div>
            </div>
        );
    };


    // Hook to calculate net monthly fee
    useEffect(() => {
        calculateNetMonthlyFee();
    }, [monthlyTuitionFee, discount]);

    //////////////////////////////////////////////////////////////////////////

    const submitForm = async () => {
        // Check if all fields are filled
        if (rollNo === "" || monthlyTuitionFee === "" || discount === "" || netMonthlyFee === "" || registrationFee === "" || admissionFee === "" || examinationFee === "") { alert("Please fill all the fields"); return }

        const data = {
            rollNo,
            newFee: {
                monthlyFee: monthlyTuitionFee,
                registrationFee,
                admissionFee,
                examinationFee,
                discount,
                netMonthlyFee,
                editedBy: user.user,
                editedOn: new Date().toLocaleString(),

            }
        }
        dispatch(startLoading());
        dispatch(editVoucher(data));
    };
    //////////////////////////////////////////////////////////////////////////
    const goBack = () => {
        dispatch(resetAdmissionState())
        navigate(-1)
    }
    return (
        <div className="flex flex-col items-start justify-center px-4">
            {admissionLoading && <Loader />}
            <Header />
            <div className="flex flex-col items-start justify-center w-full border-b border-solid border-[#DDDDDD] py-2 px-1">
                <div className="flex flex-row items-center justify-start w-full gap-4">
                    <h1 className="text-3xl font-bold text-center text-gray-800">
                        Edit Vouchers
                    </h1>
                </div>
                <h2 className="text-xl font-semibold text-center text-gray-500">
                    {campus} Campus
                </h2>
            </div>

            {financialInformation()}
            <div className="flex flex-row items-center justify-center w-full gap-4 mt-4">

                <button
                    className="px-4 py-2 text-base font-bold text-white bg-green-500 rounded-md"
                    onClick={submitForm}
                >
                    Submit
                </button>


                <button
                    className="px-4 py-2 text-base font-bold text-white bg-red-500 rounded-md"
                    onClick={goBack}
                >
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default EditVouchers;
