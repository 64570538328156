import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import Header from '../header/Header';
import { classes, sections } from '../../consts/commons';
import { getAllVouchers, startLoading, resetVoucher } from '../../actions/voucherActions';
const FeeVouchers = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const User = useSelector(state => state.user)
    //////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (!User.isSignedIn) {
            navigate("/login");
        }
    }, [User, navigate]);
    //////////////////////////////////////////////////////////////////////////
    const Vouchers = useSelector(state => state.voucher)
    const voucherLoading = Vouchers.loading
    const [selectedClass, setSelectedClass] = useState('all')
    const [selectedSection, setSelectedSection] = useState('all')
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [dataType, setDataType] = useState('')
    const [haveData, setHaveData] = useState(false);
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0)
    useEffect(() => {
        if (Vouchers.msg === 'No vouchers found') {
            alert('No vouchers found')
            setHaveData(false)
            dispatch(resetVoucher())
        }
        else if (Vouchers.msg === 'Failed to get vouchers') {
            alert('Failed to get vouchers')
            setHaveData(false)
            dispatch(resetVoucher())
        }
        else if (Vouchers.msg === 'Vouchers found') {
            if (Vouchers.vouchers.length === 0) {
                alert('No vouchers found')
                setHaveData(false)
                dispatch(resetVoucher())
            }
            else {
                setHaveData(true)
                setData(Vouchers.vouchers)
                setCount(Vouchers.vouchers.length)
            }
        }
    }, [Vouchers.msg])

    useEffect(() => {
        setHaveData(false)
        setData([])
    }
        , [selectedClass, selectedSection])

    const getReports = (type) => {
        dispatch(resetVoucher());
        dispatch(startLoading());

        setHaveData(false)
        setDataType(type)
        const data = {
            studentClass: selectedClass,
            section: selectedSection,
            fromDate: fromDate,
            toDate: toDate
        }
        dispatch(startLoading())
        dispatch(getAllVouchers(data, User.campus, type, "paid"))
    }

    return (
        <div className="flex flex-col items-start justify-center px-4 space-y-4">
            {(voucherLoading) && <Loader />}
            <Header />
            <div className="flex flex-row items-center justify-start w-full border-b border-solid border-[#DDDDDD] py-2">
                <h1 className="text-3xl font-bold text-center text-gray-800">
                    Fee received
                </h1>
                <h2 className="ml-4 text-xl font-semibold text-center text-gray-500">
                    {User.campus} Campus
                </h2>
            </div>
            <div className="flex flex-col md:flex-row items-center justify-between w-full mt-4 space-y-4 md:space-y-0 md:space-x-4 ">
                <div className="flex flex-col items-start w-full space-y-4 md:w-1/4">
                    <label className="text-lg font-semibold text-gray-600">
                        Class
                    </label>
                    <select
                        value={selectedClass}
                        onChange={(e) => setSelectedClass(e.target.value)}
                        className="w-full p-2 border border-solid border-[#DDDDDD] rounded-md"
                    >
                        <option value="all">All</option>
                        {classes.map((item, index) => (
                            <option key={index} value={item}>
                                {item}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="flex flex-col items-start w-full space-y-4 md:w-1/4">
                    <label className="text-lg font-semibold text-gray-600">
                        Section
                    </label>
                    <select
                        value={selectedSection}
                        onChange={(e) => setSelectedSection(e.target.value)}
                        className="w-full p-2 border border-solid border-[#DDDDDD] rounded-md"
                    >
                        <option value="all">All</option>
                        {sections.map((item, index) => (
                            <option key={index} value={item}>
                                {item}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="flex flex-col items-start w-full space-y-4 md:w-1/4">
                    <label className="text-lg font-semibold text-gray-600">
                        From Date (Issue Date)
                    </label>
                    <input
                        type="date"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                        className="w-full p-2 border border-solid border-[#DDDDDD] rounded-md"
                    />
                </div>
                <div className="flex flex-col items-start w-full space-y-4 md:w-1/4">
                    <label className="text-lg font-semibold text-gray-600">
                        To Date (Issue Date)
                    </label>
                    <input
                        type="date"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                        className="w-full p-2 border border-solid border-[#DDDDDD] rounded-md"
                    />
                </div>
            </div>
            <div className="flex flex-row items-center justify-start mt-4 space-x-4 border-b border-solid border-[#DDDDDD] pb-4">
                <button
                    className="p-2 px-4 text-lg font-semibold text-white  bg-cyan-700 rounded-md"
                    onClick={() => getReports('admitted')}
                >
                    Generate Report for Regular
                </button>
                <button
                    className="p-2 px-4 text-lg font-semibold text-white bg-[#FF8C00] rounded-md"
                    onClick={() => getReports('removed')}
                >
                    Generate Report for students left
                </button>
                <button
                    className="p-2 px-4 text-lg font-semibold text-white bg-[#2ECC71] rounded-md"
                    onClick={() => getReports('all')}
                >
                    Generate Report for both
                </button>
                <button
                    className="p-2 px-4 text-lg font-semibold text-white	 bg-[#FF5757] rounded-md"
                    onClick={() => navigate(-1)}
                >
                    Cancel
                </button>
            </div>

            {true ?
                <div className='flex flex-col items-start justify-center w-full mt-4 space-y-4 '>
                    <div className='flex flex-col items-center justify-between w-full  py-2 pb-10'>
                        <div className='flex flex-row items-center justify-between w-full p-2'>
                            <h1 className='text-xl font-bold text-gray-800'>Fee vouchers for {dataType} students</h1>
                        </div>

                        <table className="min-w-full border border-solid border-[#CCCCCC]">
                            <thead className="text-xs font-semibold uppercase bg-gray-200 text-gray-600">
                                <tr>
                                    <th className="px-2 py-3 text-center">Voucher #</th>
                                    <th className="px-2 py-3 text-center">Issue Date</th>
                                    <th className="px-2 py-3 text-center">Roll No.</th>
                                    <th className="px-2 py-3 text-center">Studnet Name</th>

                                    <th className="px-2 py-3 text-center">Received</th>
                                    {/* NOTE: LEDGERS CLEANING PENDING */}
                                </tr>
                            </thead>
                            <tbody className="text-xs bg-white text-gray-600">
                                {data.map((item, index) => (
                                    <tr key={index}>
                                        <td className="px-2 py-3 text-center">{item.voucherNo}</td>
                                        <td className="px-2 py-3 text-center">{item.issueDate}</td>
                                        <td className="px-2 py-3 text-center">{item.rollNo}</td>
                                        <td className="px-2 py-3 text-center">{item.studentName}</td>

                                        <td className="px-2 py-3 text-center">{
                                            item.receivedAmount
                                        }</td>
                                    </tr>
                                ))
                                }
                                <tr className="text-xs font-semibold uppercase bg-gray-200 text-gray-600">
                                    <td className="px-2 py-3 text-center">{count}</td>
                                    <td className="px-2 py-3 text-center">Total</td>
                                    <td className="px-2 py-3 text-center"></td>
                                    <td className="px-2 py-3 text-center"></td>
                                    <td className="px-2 py-3 text-center text-red-500">
                                        {data.reduce((acc, item) => acc + parseInt(item.receivedAmount), 0)}
                                    </td>

                                </tr>
                            </tbody>

                        </table>


                    </div>


                </div>
                : null}

        </div >
    )
}

export default FeeVouchers