import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import Header from '../header/Header';
import { classes, sections } from '../../consts/commons';
import Logo from "../../assets/logo.png";

import { getAllVouchers, startLoading, resetVoucher } from '../../actions/voucherActions';
const FeeVouchers = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const User = useSelector(state => state.user)
  //////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (!User.isSignedIn) {
      navigate("/login");
    }
  }, [User, navigate]);
  //////////////////////////////////////////////////////////////////////////
  const Vouchers = useSelector(state => state.voucher)
  const voucherLoading = Vouchers.loading
  const [selectedClass, setSelectedClass] = useState('all')
  const [selectedSection, setSelectedSection] = useState('all')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [dataType, setDataType] = useState('')
  const [haveData, setHaveData] = useState(false);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0)
  const [renderVouchers, setRenderVouchers] = useState(false)
  useEffect(() => {
    if (Vouchers.msg === 'No vouchers found') {
      alert('No vouchers found')
      setHaveData(false)
      dispatch(resetVoucher())
    }
    else if (Vouchers.msg === 'Failed to get vouchers') {
      alert('Failed to get vouchers')
      setHaveData(false)
      dispatch(resetVoucher())
    }
    else if (Vouchers.msg === 'Vouchers found') {
      if (Vouchers.vouchers.length === 0) {
        alert('No vouchers found')
        setHaveData(false)
        dispatch(resetVoucher())
      }
      else {
        setHaveData(true)
        setData(Vouchers.vouchers)
        setCount(Vouchers.vouchers.length)
        setHaveData(true)

      }
    }
  }, [Vouchers.msg])

  // useEffect(() => {
  //   setHaveData(false)
  //   setData([])
  // }
  //   , [selectedClass, selectedSection])

  const getReports = (type) => {
    dispatch(resetVoucher());
    dispatch(startLoading());

    setHaveData(false)
    setDataType(type)
    const data = {
      studentClass: selectedClass,
      section: selectedSection,
      fromDate: fromDate,
      toDate: toDate
    }
    dispatch(startLoading())
    dispatch(getAllVouchers(data, User.campus, type, "unpaid"))
  }
  const getTotal = (monthlyFee, examinationFee, fine, discount, registrationFee, admissionFee) => {
    let total = 0;
    if (!isNaN(monthlyFee)) {
      total += parseInt(monthlyFee);
    }
    if (!isNaN(examinationFee)) {
      total += parseInt(examinationFee);
    }
    if (!isNaN(fine)) {
      total += parseInt(fine);
    }
    if (!isNaN(discount)) {
      total -= parseInt(discount);
    }
    if (!isNaN(registrationFee)) {
      total += parseInt(registrationFee);
    }
    if (!isNaN(admissionFee)) {
      total += parseInt(admissionFee);
    }
    return total;
  }
  // useEffect(() => {
  //   const data = {
  //     fromDate: "2024-03-01",
  //     section: "all",
  //     studentClass: "all",
  //     toDate: "2024-04-11",
  //   }
  //   dispatch(getAllVouchers(data, User.campus, "admitted", "unpaid"))
  // }, [])
  function formatDate(inputDate, adddays = 0) {
    // Create a new Date object from the input date string

    const date = new Date(inputDate);
    if (adddays > 0) {
      date.setDate(date.getDate() + adddays);
    }
    // Define an array of month names
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"];

    // Extract the month, day, and year from the Date object
    const month = monthNames[date.getMonth()];
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();

    // Concatenate the formatted date
    const formattedDate = `${month} ${day}, ${year}`;

    return formattedDate;
  }
  // abnormality used for vouchers dk why
  const calculateTotal = (monthlyFee, examinationFee, arrears, discount, registrationFee, admissionFee) => {
    let total = 0;
    if (!isNaN(monthlyFee)) {
      total += parseInt(monthlyFee);
    }
    if (!isNaN(examinationFee)) {
      total += parseInt(examinationFee);
    }
    // if (!isNaN(fine)) {
    //   total += parseInt(fine);
    // }
    if (!isNaN(discount)) {
      total -= parseInt(discount);
    }
    if (!isNaN(registrationFee)) {
      total += parseInt(registrationFee);
    }
    if (!isNaN(admissionFee)) {
      total += parseInt(admissionFee);
    }
    if (!isNaN(arrears)) {
      total += parseInt(arrears);
    }

    return total;
  }
  const VoucherComponent = ({ feeData }) => {
    return (
      <div className='flex flex-col items-start justify-center w-full'>
        {/* Parent Copy */}
        <div className='flex flex-col items-start justify-center w-full'>
          <div className="flex flex-row items-center justify-start gap-1  w-full p-1">
            <div className='flex flex-row items-center justify-start gap-1 w-full'>
              <img src={Logo} alt="Logo" width={45} height={45} />
              {" "}
              <div className='flex flex-row items-center justify-start w-full gap-2 font-sans font-normal text-xs'>
                <p className='font-sans font-semibold text-lg'>
                  The Arqam School{" "}
                </p>
                <p className='text-xs'>
                  NTN NO: 4920811
                </p>

              </div>
            </div>
            <div className='flex flex-row items-center justify-end w-full font-sans font-normal text-sm'>
              Parent Copy
            </div>
          </div>
          <div className='flex flex-col items-start justify-center w-full p-1 mt-1'>
            {/* <div className='flex flex-row items-center justify-end w-full font-sans font-medium text-xs'>
              Month: <span >{feeData.feePeriod}</span>
            </div> */}
            <div className='flex flex-row item-center justify-between w-full gap-[10%]'>
              <div className='flex flex-col items-start justify-center w-full gap-1 font-sans font-normal text-xs'>
                <div className=' font-sans font-normal text-base'>
                  Student Information:
                </div>
                <div className='w-full items-center justify-between  flex flex-row'>
                  Student Name: <span>{feeData.studentName}</span>
                </div>
                <div className='w-full items-center justify-between  flex flex-row'>
                  Roll Number: <span>{feeData.rollNo}</span>
                </div>
                <div className='w-full items-center justify-between  flex flex-row'>
                  Class: <span>{feeData.studentClass}</span>
                </div>
                <div className='w-full items-center justify-between  flex flex-row'>
                  Section: <span>{feeData.section}</span>
                </div>
              </div>
              {/*  */}
              <div className='flex flex-col items-start justify-center w-full gap-1 font-sans font-normal text-xs'>
                <div className=' font-sans font-normal text-base'>
                  Charges:
                </div>
                <div className='w-full items-center justify-between flex flex-row'>
                  Tution Fee {data.feePeriod}: <span>{feeData.monthlyFee}</span>
                </div>
                <div className='w-full items-center justify-between flex flex-row'>
                  Admission Fee: <span>{feeData.admissionFee}</span>
                </div>
                <div className='w-full items-center justify-between flex flex-row'>
                  Reg. Fee: <span>{feeData.registrationFee}</span>
                </div>
                <div className='w-full items-center justify-between flex flex-row'>
                  Examination Fee: <span>{feeData.examinationFee}</span>
                </div>
              </div>

            </div>
            {/* voucher */}
            <div className='flex flex-row item-center justify-between w-full gap-[10%]'>
              <div className='flex flex-col items-start justify-center w-full gap-2 font-sans font-normal text-xs'>
                <div className=' font-sans font-normal text-base'>
                  Challan Information:
                </div>
                <div className='w-full items-center justify-between  flex flex-row'>
                  Voucher No.: <span>{feeData.voucherNo}</span>
                </div>
                <div className='w-full items-center justify-between  flex flex-row'>
                  Fee Period: <span>{feeData.feePeriod}</span>
                </div>
                <div className='w-full items-center justify-between  flex flex-row'>
                  Issue date: <span>{formatDate(feeData.issueDate)}</span>
                </div>
                <div className='w-full items-center justify-between  flex flex-row'>
                  Due Date: <span>{formatDate(feeData.dueDate)}</span>
                </div>
                <div className='w-full items-center justify-between  flex flex-row'>
                  Validity Date: <span>{formatDate(feeData.dueDate, 10)}</span>
                </div>
              </div>
              {/*  */}
              <div className='flex flex-col items-start justify-center w-full gap-2 font-sans font-normal text-xs'>
                <div className='py-3'>

                </div>
                <div className='w-full items-center justify-between flex flex-row'>
                  Discount: <span>{feeData.discount}</span>
                </div>
                <div className='w-full items-center justify-between flex flex-row'>
                  Arrears: <span>{feeData.arrears}</span>
                </div>
                <div className='w-full items-center justify-between flex flex-row'>
                  Payable by {formatDate(feeData.dueDate)} : <span className='w-[18%] py-1 border border-solid border-black text-right pr-1 font-semibold'>
                    {calculateTotal(feeData.monthlyFee, feeData.examinationFee, feeData.arrears, feeData.discount, feeData.registrationFee, feeData.admissionFee)}
                  </span>
                </div>
                <div className='w-full items-center justify-between flex flex-row'>

                </div>
                <div className='w-full items-center justify-between flex flex-row'>
                  Payable after Due date : <span className='w-[18%] py-1 border border-solid border-black text-right pr-1 font-semibold'>                    {calculateTotal(feeData.monthlyFee, feeData.examinationFee, feeData.arrears, feeData.discount, feeData.registrationFee, feeData.admissionFee)}
                  </span>
                </div>
              </div>

            </div>
          </div>
        </div>
        {/* School copy */}
        <div className='flex flex-col items-start justify-center w-full mt-4'>
          <div className="flex flex-row items-center justify-start gap-1  w-full p-1">
            <div className='flex flex-row items-center justify-start gap-1 w-full'>
              <img src={Logo} alt="Logo" width={45} height={45} />
              {" "}
              <div className='flex flex-row items-center justify-start w-full gap-2 font-sans font-normal text-xs'>
                <p className='font-sans font-semibold text-lg'>
                  The Arqam School{" "}
                </p>
                <p className='text-xs'>
                  NTN NO: 4920811
                </p>

              </div>
            </div>
            <div className='flex flex-row items-center justify-end w-full font-sans font-normal text-sm'>
              School Copy
            </div>
          </div>
          <div className='flex flex-col items-start justify-center w-full p-1  mt-1'>
            {/* <div className='flex flex-row items-center justify-end w-full font-sans font-medium text-xs'>
              Month: <span >{feeData.feePeriod}</span>
            </div> */}
            <div className='flex flex-row item-center justify-between w-full gap-[10%]'>
              <div className='flex flex-col items-start justify-center w-full gap-1 font-sans font-normal text-xs'>
                <div className=' font-sans font-normal text-base'>
                  Student Information:
                </div>
                <div className='w-full items-center justify-between  flex flex-row'>
                  Student Name: <span>{feeData.studentName}</span>
                </div>
                <div className='w-full items-center justify-between  flex flex-row'>
                  Roll Number: <span>{feeData.rollNo}</span>
                </div>
                <div className='w-full items-center justify-between  flex flex-row'>
                  Class: <span>{feeData.studentClass}</span>
                </div>
                <div className='w-full items-center justify-between  flex flex-row'>
                  Section: <span>{feeData.section}</span>
                </div>
              </div>
              {/*  */}
              <div className='flex flex-col items-start justify-center w-full gap-1 font-sans font-normal text-xs'>
                <div className=' font-sans font-normal text-base'>
                  Charges:
                </div>
                <div className='w-full items-center justify-between flex flex-row'>
                  Tution Fee {data.feePeriod}: <span>{feeData.monthlyFee}</span>
                </div>
                <div className='w-full items-center justify-between flex flex-row'>
                  Admission Fee: <span>{feeData.admissionFee}</span>
                </div>
                <div className='w-full items-center justify-between flex flex-row'>
                  Reg. Fee: <span>{feeData.registrationFee}</span>
                </div>
                <div className='w-full items-center justify-between flex flex-row'>
                  Examination Fee: <span>{feeData.examinationFee}</span>
                </div>
              </div>

            </div>
            {/* voucher */}
            <div className='flex flex-row item-center justify-between w-full gap-[10%]'>
              <div className='flex flex-col items-start justify-center w-full gap-2 font-sans font-normal text-xs'>
                <div className=' font-sans font-normal text-base'>
                  Challan Information:
                </div>
                <div className='w-full items-center justify-between  flex flex-row'>
                  Voucher No.: <span>{feeData.voucherNo}</span>
                </div>
                <div className='w-full items-center justify-between  flex flex-row'>
                  Fee Period: <span>{feeData.feePeriod}</span>
                </div>
                <div className='w-full items-center justify-between  flex flex-row'>
                  Issue date: <span>{formatDate(feeData.issueDate)}</span>
                </div>
                <div className='w-full items-center justify-between  flex flex-row'>
                  Due Date: <span>{formatDate(feeData.dueDate)}</span>
                </div>
                <div className='w-full items-center justify-between  flex flex-row'>
                  Validity Date: <span>{formatDate(feeData.dueDate, 10)}</span>
                </div>
              </div>
              {/*  */}
              <div className='flex flex-col items-start justify-center w-full gap-2 font-sans font-normal text-xs'>
                <div className='py-3'>

                </div>
                <div className='w-full items-center justify-between flex flex-row'>
                  Discount: <span>{feeData.discount}</span>
                </div>
                <div className='w-full items-center justify-between flex flex-row'>
                  Arrears: <span>{feeData.arrears}</span>
                </div>
                <div className='w-full items-center justify-between flex flex-row'>
                  Payable by {formatDate(feeData.dueDate)} : <span className='w-[18%] py-1 border border-solid border-black text-right pr-1 font-semibold'>                    {calculateTotal(feeData.monthlyFee, feeData.examinationFee, feeData.arrears, feeData.discount, feeData.registrationFee, feeData.admissionFee)}
                  </span>
                </div>
                <div className='w-full items-center justify-between flex flex-row'>

                </div>
                <div className='w-full items-center justify-between flex flex-row'>
                  Payable after Due date : <span className='w-[18%] py-1 border border-solid border-black text-right pr-1 font-semibold'>                    {calculateTotal(feeData.monthlyFee, feeData.examinationFee, feeData.arrears, feeData.discount, feeData.registrationFee, feeData.admissionFee)}
                  </span>
                </div>
              </div>

            </div>
          </div>
        </div>
        {/* Bank Copy */}
        <div className='flex flex-col items-start justify-center w-full mt-4'>
          <div className="flex flex-row items-center justify-start gap-1  w-full p-1">
            <div className='flex flex-row items-center justify-start gap-1 w-full'>
              <img src={Logo} alt="Logo" width={45} height={45} />
              {" "}
              <div className='flex flex-row items-center justify-start w-full gap-2 font-sans font-normal text-xs'>
                <p className='font-sans font-semibold text-lg'>
                  The Arqam School{" "}
                </p>
                <p className='text-xs'>
                  NTN NO: 4920811
                </p>

              </div>
            </div>
            <div className='flex flex-row items-center justify-end w-full font-sans font-normal text-sm'>
              Bank Copy
            </div>
          </div>
          <div className='flex flex-col items-start justify-center w-full p-1  mt-1'>
            {/* <div className='flex flex-row items-center justify-end w-full font-sans font-medium text-xs'>
              Month: <span >{feeData.feePeriod}</span>
            </div> */}
            <div className='flex flex-row item-center justify-between w-full gap-[10%]'>
              <div className='flex flex-col items-start justify-center w-full gap-1 font-sans font-normal text-xs'>
                <div className=' font-sans font-normal text-base'>
                  Student Information:
                </div>
                <div className='w-full items-center justify-between  flex flex-row'>
                  Student Name: <span>{feeData.studentName}</span>
                </div>
                <div className='w-full items-center justify-between  flex flex-row'>
                  Roll Number: <span>{feeData.rollNo}</span>
                </div>
                <div className='w-full items-center justify-between  flex flex-row'>
                  Class: <span>{feeData.studentClass}</span>
                </div>
                <div className='w-full items-center justify-between  flex flex-row'>
                  Section: <span>{feeData.section}</span>
                </div>
              </div>
              {/*  */}
              <div className='flex flex-col items-start justify-center w-full gap-1 font-sans font-normal text-xs'>
                <div className=' font-sans font-normal text-base'>
                  Charges:
                </div>
                <div className='w-full items-center justify-between flex flex-row'>
                  Tution Fee {data.feePeriod}: <span>{feeData.monthlyFee}</span>
                </div>
                <div className='w-full items-center justify-between flex flex-row'>
                  Admission Fee: <span>{feeData.admissionFee}</span>
                </div>
                <div className='w-full items-center justify-between flex flex-row'>
                  Reg. Fee: <span>{feeData.registrationFee}</span>
                </div>
                <div className='w-full items-center justify-between flex flex-row'>
                  Examination Fee: <span>{feeData.examinationFee}</span>
                </div>
              </div>

            </div>
            {/* voucher */}
            <div className='flex flex-row item-center justify-between w-full gap-[10%]'>
              <div className='flex flex-col items-start justify-center w-full gap-2 font-sans font-normal text-xs'>
                <div className=' font-sans font-normal text-base'>
                  Challan Information:
                </div>
                <div className='w-full items-center justify-between  flex flex-row'>
                  Voucher No.: <span>{feeData.voucherNo}</span>
                </div>
                <div className='w-full items-center justify-between  flex flex-row'>
                  Fee Period: <span>{feeData.feePeriod}</span>
                </div>
                <div className='w-full items-center justify-between  flex flex-row'>
                  Issue date: <span>{formatDate(feeData.issueDate)}</span>
                </div>
                <div className='w-full items-center justify-between  flex flex-row'>
                  Due Date: <span>{formatDate(feeData.dueDate)}</span>
                </div>
                <div className='w-full items-center justify-between  flex flex-row'>
                  Validity Date: <span>{formatDate(feeData.dueDate, 10)}</span>
                </div>
              </div>
              {/*  */}
              <div className='flex flex-col items-start justify-center w-full gap-2 font-sans font-normal text-xs'>
                <div className='py-3'>

                </div>
                <div className='w-full items-center justify-between flex flex-row'>
                  Discount: <span>{feeData.discount}</span>
                </div>
                <div className='w-full items-center justify-between flex flex-row'>
                  Arrears: <span>{feeData.arrears}</span>
                </div>
                <div className='w-full items-center justify-between flex flex-row'>
                  Payable by {formatDate(feeData.dueDate)} : <span className='w-[18%] py-1 border border-solid border-black text-right pr-1 font-semibold'>                    {calculateTotal(feeData.monthlyFee, feeData.examinationFee, feeData.arrears, feeData.discount, feeData.registrationFee, feeData.admissionFee)}
                  </span>
                </div>
                <div className='w-full items-center justify-between flex flex-row'>

                </div>
                <div className='w-full items-center justify-between flex flex-row'>
                  Payable after Due date : <span className='w-[18%] py-1 border border-solid border-black text-right pr-1 font-semibold'>{calculateTotal(feeData.monthlyFee, feeData.examinationFee, feeData.arrears, feeData.discount, feeData.registrationFee, feeData.admissionFee)}
                  </span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div >
    )
  }
  const print = async () => {
    setRenderVouchers(true)
  }

  // this function will be called before printing starts
  useEffect(() => {

    const handleAfterPrint = () => {
      // Set renderVouchers back to false after printing is done
      setRenderVouchers(false);
    };

    if (renderVouchers) {
      window.addEventListener('afterprint', handleAfterPrint);

      // Print only when renderVouchers is true
      window.print();

      return () => {
        window.removeEventListener('afterprint', handleAfterPrint);
      };
    }
  }, [renderVouchers, setRenderVouchers]);
  return (

    <div>
      <div>
        {renderVouchers ?
          data.map((item, index) => (
            <div>
              <VoucherComponent key={index} feeData={item} />
              {/* add page break */}
            </div>
          ))

          : <div className="flex flex-col items-start justify-center px-4 space-y-4">
            {(voucherLoading) && <Loader />}
            <Header />
            <div className="flex flex-row items-center justify-start w-full border-b border-solid border-[#DDDDDD] py-2">
              <h1 className="text-3xl font-bold text-center text-gray-800">
                Print Fee Vouchers
              </h1>
              <h2 className="ml-4 text-xl font-semibold text-center text-gray-500">
                {User.campus} Campus
              </h2>
            </div>
            <div className="flex flex-col md:flex-row items-center justify-between w-full mt-4 space-y-4 md:space-y-0 md:space-x-4 ">
              <div className="flex flex-col items-start w-full space-y-4 md:w-1/4">
                <label className="text-lg font-semibold text-gray-600">
                  Class
                </label>
                <select
                  value={selectedClass}
                  onChange={(e) => setSelectedClass(e.target.value)}
                  className="w-full p-2 border border-solid border-[#DDDDDD] rounded-md"
                >
                  <option value="all">All</option>
                  {classes.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col items-start w-full space-y-4 md:w-1/4">
                <label className="text-lg font-semibold text-gray-600">
                  Section
                </label>
                <select
                  value={selectedSection}
                  onChange={(e) => setSelectedSection(e.target.value)}
                  className="w-full p-2 border border-solid border-[#DDDDDD] rounded-md"
                >
                  <option value="all">All</option>
                  {sections.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col items-start w-full space-y-4 md:w-1/4">
                <label className="text-lg font-semibold text-gray-600">
                  From Date (Issue Date)
                </label>
                <input
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  className="w-full p-2 border border-solid border-[#DDDDDD] rounded-md"
                />
              </div>
              <div className="flex flex-col items-start w-full space-y-4 md:w-1/4">
                <label className="text-lg font-semibold text-gray-600">
                  To Date (Issue Date)
                </label>
                <input
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  className="w-full p-2 border border-solid border-[#DDDDDD] rounded-md"
                />
              </div>
            </div>
            <div className="flex flex-row items-center justify-start mt-4 space-x-4 border-b border-solid border-[#DDDDDD] pb-4">
              <button
                className="p-2 px-4 text-lg font-semibold text-white  bg-cyan-700 rounded-md"
                onClick={() => getReports('admitted')}
              >
                Show Data
              </button>
              <button
                className="p-2 px-4 text-lg font-semibold text-white bg-[#FF8C00] rounded-md"
                onClick={() => print()}
              >
                Print Vouchers
              </button>
              {/* <button
            className="p-2 px-4 text-lg font-semibold text-white bg-[#2ECC71] rounded-md"
            onClick={() => getReports('all')}
          >
            Generate Report for both
          </button> */}
              <button
                className="p-2 px-4 text-lg font-semibold text-white	 bg-[#FF5757] rounded-md"
                onClick={() => navigate(-1)}
              >
                Cancel
              </button>
            </div>

            {haveData ?
              <div className='flex flex-col items-start justify-center w-full mt-4 space-y-4 '>
                <div className='flex flex-col items-center justify-between w-full  py-2 pb-10'>
                  <div className='flex flex-row items-center justify-between w-full p-2'>
                    <h1 className='text-xl font-bold text-gray-800'>Fee vouchers for {dataType} students</h1>
                  </div>

                  <table className="min-w-full border border-solid border-[#CCCCCC]">
                    <thead className="text-xs font-semibold uppercase bg-gray-200 text-gray-600">
                      <tr>
                        <th className="px-2 py-3 text-center">Voucher #</th>
                        <th className="px-2 py-3 text-center">Issue Date</th>
                        <th className="px-2 py-3 text-center">Tution Fee</th>
                        <th className="px-2 py-3 text-center">Examination Fee</th>
                        <th className="px-2 py-3 text-center">Registration Fee</th>
                        <th className="px-2 py-3 text-center">Admission Fee</th>
                        <th className="px-2 py-3 text-center">Fine</th>
                        <th className="px-2 py-3 text-center">Discount</th>
                        <th className="px-2 py-3 text-center">Roll No.</th>
                        <th className="px-2 py-3 text-center">Studnet Name</th>

                        <th className="px-2 py-3 text-center">Arrears</th>
                        <th className="px-2 py-3 text-center">This Month receivables</th>
                        <th className="px-2 py-3 text-center">Net receivables</th>

                      </tr>
                    </thead>
                    <tbody className="text-xs bg-white text-gray-600">
                      {data.map((item, index) => (
                        <tr key={index}>
                          <td className="px-2 py-3 text-center">{item.voucherNo}</td>
                          <td className="px-2 py-3 text-center">{item.issueDate}</td>
                          <td className="px-2 py-3 text-center">{item.monthlyFee}</td>

                          <td className="px-2 py-3 text-center">{item.examinationFee}</td>
                          <td className="px-2 py-3 text-center">{item.registrationFee}</td>
                          <td className="px-2 py-3 text-center">{item.admissionFee}</td>

                          <td className="px-2 py-3 text-center">{item.fine ?
                            item.fine : 0
                          }</td>
                          <td className="px-2 py-3 text-center">{item.discount}</td>
                          <td className="px-2 py-3 text-center">{item.rollNo}</td>
                          <td className="px-2 py-3 text-center">{item.studentName}</td>

                          <td className="px-2 py-3 text-center">{item.arrears}</td>
                          <td className="px-2 py-3 text-center">{
                            getTotal(item.monthlyFee, item.examinationFee, item.fine, item.discount, item.registrationFee, item.admissionFee)
                          }</td>
                          <td className="px-2 py-3 text-center">{parseFloat(item.arrears) + parseFloat(getTotal(item.monthlyFee, item.examinationFee, item.fine, item.discount, item.registrationFee, item.admissionFee))}</td>

                        </tr>
                      ))
                      }
                      <tr className="text-xs font-semibold uppercase bg-gray-200 text-gray-600">
                        <td className="px-2 py-3 text-center">{count}</td>
                        <td className="px-2 py-3 text-center">Total</td>
                        <td className="px-2 py-3 text-center text-red-500">{data.reduce((acc, item) => acc + parseInt(item.monthlyFee), 0)}</td>
                        <td className="px-2 py-3 text-center text-red-500">{data.reduce((acc, item) => acc + parseInt(item.examinationFee), 0)}</td>
                        <td className="px-2 py-3 text-center text-red-500">{data.reduce((acc, item) => acc + parseInt(item.registrationFee), 0)}</td>
                        <td className="px-2 py-3 text-center text-red-500">{data.reduce((acc, item) => acc + parseInt(item.admissionFee), 0)}</td>

                        <td className="px-2 py-3 text-center">0</td>
                        <td className="px-2 py-3 text-center text-red-500">{data.reduce((acc, item) => acc + parseInt(item.discount), 0)}</td>
                        <td className="px-2 py-3 text-center"></td>
                        <td className="px-2 py-3 text-center"></td>
                        <td className="px-2 py-3 text-center text-red-500">{data.reduce((acc, item) => acc + parseInt(item.arrears), 0)}</td>
                        <td className="px-2 py-3 text-center text-red-500">{data.reduce((acc, item) => acc + getTotal(item.monthlyFee, item.examinationFee, item.fine, item.discount, item.registrationFee, item.admissionFee), 0)}</td>
                        <td className="px-2 py-3 text-center text-red-500">{data.reduce((acc, item) => acc + parseFloat(item.arrears) + parseFloat(getTotal(item.monthlyFee, item.examinationFee, item.fine, item.discount, item.registrationFee, item.admissionFee)), 0)}</td>

                      </tr>
                    </tbody>

                  </table>


                </div>


              </div>
              : null}

          </div >}

      </div>

    </div>

  )
}

export default FeeVouchers